import React, { useContext, useState } from "react";
import { Table, Tabs, Input, Button, Select, DatePicker, Dropdown, message } from "antd";
import "./challenges-home.scss";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDateWithoutDay } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import Filter from "../../../assets/images/filter.svg";
import { UserContext } from "../../../context/user-context";
import {
  FeaturedWorkoutContext,
  FeaturedWorkoutContextProvider,
} from "../../../context/challenge-workout-context/featured-workout-context";
const FeaturedWorkoutsPage = () => {
  const { color, lightButtonBackground, selectedSport , accountDetail } =
    useContext(UserContext);
  const dateFormat = "ddd. MMMM D, YYYY";
  const {
    sort,
    setSort,
    setStartDate,
    setEndDate,
    allFeaturedWorkouts,
    limit,
    offset,
    totalWorkouts,
    setOffset,
    loading,
    setAllFeaturedWorkouts,
    setSearch,
    setType,
    pageNumber,
    setLimit,
    setPageNumber,
    deleteFeatureWorkout

  } = useContext(FeaturedWorkoutContext);
  const navigate = useNavigate();
  const onChange = (key) => {
    if(key === "all"){
      setType("")
    }else{
      setType("archive")
    }
  };
  const tabItems = [
    {
      key: "all",
      label: `All`,
    },
    {
      key: "archived",
      label: `Archived`,
    },
  ];
  const items = [
    {
      key: "edit",
      label: "Edit",
    },
    {
      key: "detail",
      label: "View Detail",
    },
    {
      key: "delete",
      label: "Delete",
    },
  ];
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const { RangePicker } = DatePicker;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis:true
    },
    {
      title: "Sport",
      dataIndex: "sport_name",
      key: "sport_name",
    },
    {
      title: "tasks",
      dataIndex: "tasks",
      key: "tasks",
    },
    {
      title: "taken by",
      dataIndex: "taken_by",
      key: "taken_by",
      // width:"15%",
      render: (text, record, index) => {
        return <div key={index}>{record.taken_by} members</div>;
      },
    },
    {
      title: "presented by",
      dataIndex: "presented_by",
      key: "presented_by",
      width:"20%",
      ellipsis:true,
      render: (text, record, index) => {
        return (
          <div key={index} style={{textOverflow:"ellipsis"}}>
            {record.presented_by ? record.presented_by : "N/A"}
          </div>
        );
      },
    },
    {
      title: "ending",
      dataIndex: "ending_on",
      key: "ending_on",
      // width:"15%",
      render: (text, record, index) => {
        return <div key={index}>{getDateWithoutDay(record.ending_on)}</div>;
      },
    },
    {
      title: "points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "ACTIONS",
      dataIndex: "action",
      key: "action",
      width:"15%",
      render: (text, record, key) => (
        <Dropdown
          placeholder="Actions"
          className={
            "custom-dropdown " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
          menu={{
            items,
            onClick: ({ key }) => {
              // setSelectedWorkout(record)
              // if (key === "delete") {
              //   // if(accountDetail.role !== null){
              //   //   for (let item of accountDetail.role?.role_permission) {
              //   //   if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "DELETE"){
              //   //     if(item.value === false){
              //   //       info();
              //   //     }
              //   //     else{
              //   //       setDeleteWorkoutModal(true)
              //   //       setSelectedWorkout(record)
              //   //      }
              //   //   }
              //   // }
              //   // }else{
              //   //   setDeleteWorkoutModal(true)
              //   //   setSelectedWorkout(record)
              //   // }
              // } else if (key === "detail") {
              //   // navigate({
              //   //   pathname: `./workout-detail`,
              //   //   search: `?id=${record.id}`,
              //   // })
              if(key === "detail"){
                navigate({
                  pathname: `./${record.title}`,
                  search: `?tab=featured-workouts&id=${record.id}`,
                });
              }              
              else if (key === "delete") {
                  if(accountDetail.role !== null){
                    for (let item of accountDetail.role?.role_permission) {
                    if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "DELETE"){
                      if(item.value === false){
                        info();
                      }
                      else{
                        // setDeleteWorkoutModal(true)
                        // setSelectedWorkout(record)
                        deleteFeatureWorkout(record.id)
                       }
                    }
                  }
                  }else{
                    // setDeleteWorkoutModal(true)
                    // setSelectedWorkout(record)
                    deleteFeatureWorkout(record.id)
                  }
                }
              else{
                      if(accountDetail.role !== null){
                  for (let item of accountDetail.role?.role_permission) {
                    if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "EDIT"){
                      if(item.value === false){
                        info();
                      }
                      else{
                        navigate({
                          pathname: `./edit-featured-workout`,
                           search: `?id=${record.id}`
    
                        })
                       }
                    }
                  }
                }
                else{
                  navigate({
                    pathname: `./edit-featured-workout`,
                     search: `?id=${record.id}`
                  })
                 }
              }
              // } else {
              //   //   if(accountDetail.role !== null){
              //   //   for (let item of accountDetail.role?.role_permission) {
              //   //     if(item.module.name === "Challenges / Workouts" && item.permission.code_name === "EDIT"){
              //   //       if(item.value === false){
              //   //         info();
              //   //       }
              //   //       else{
              //   //         navigate({
              //   //           pathname: `./edit-workout`,
              //   //           search: `?id=${record.id}`,
              //   //         })
              //   //        }
              //   //     }
              //   //   }
              //   // }
              //   // else{
              //   //   navigate({
              //   //     pathname: `./edit-workout`,
              //   //     search: `?id=${record.id}`,
              //   //   })
              //   //  }
              //   // setSelectedSport(record);
              //   // setEditSportModal(true);
              // }
            },
          }}
          overlayClassName={
            "custom-dropdown-menu " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current !== pageNumber) {
      setPageNumber(pagination.current)
      setOffset(pagination.current - 1 +"0");
    }
  };
  const onChangeRangePicker = (e) => {
    if (e) {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const searchFeatureWorkout = (e) => {
    if (e.target.value.length >= 3) {
      setLimit(10)
      setOffset(0)
      setAllFeaturedWorkouts([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllFeaturedWorkouts((allFeaturedWorkouts) => []);
      setSearch("");
    }
  };
  return (
    <div className="challenges-home-main">
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        onChange={onChange}
        className={selectedSport === 1 ? "orange-theme" : "blue-theme"}
        destroyInactiveTabPane
      />
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchFeatureWorkout}
          />
          <div className="right-div">
            <Select
              placeholder="Newest"
              onChange={(e) => setSort(e)}
              value={sort}
              style={{ marginLeft: "auto" }}
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              popupClassName={
                "custom-action-select-dropdown " +
                (selectedSport === 1
                  ? "orange-theme-select"
                  : "blue-theme-select")
              }
              options={[
                {
                  value: true,
                  label: "Newest",
                },
                {
                  value: false,
                  label: "Oldest",
                },
              ]}
            />
            <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
              onChange={onChangeRangePicker}
            />
            {/* <button
              className="red-base-btn"
              style={{ color: color, background: lightButtonBackground }}
            >
              <img
                src={Filter}
                alt="filter"
                className={
                  selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg"
                }
              />
            </button> */}
          </div>
        </div>
        <Table
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          loading={loading}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: false,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: limit,
            total: totalWorkouts,
          }}
          columns={columns}
          dataSource={allFeaturedWorkouts}
          className={
            "table-container " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />
      </div>
    </div>
  );
};
const FeaturedWorkouts = () => {
  return (
    <FeaturedWorkoutContextProvider>
      <FeaturedWorkoutsPage />
    </FeaturedWorkoutContextProvider>
  );
};

export default FeaturedWorkouts;
