import React, { useContext, useEffect } from "react";
import { Card, Col, Empty, Row, Select , Spin} from "antd";
import Play from "../../../assets/images/play.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import Thumbnail from "../../../assets/images/chatLogo.svg";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import ShootingDetailModal from "./shooting-detail-modal";
import "./vision-shooting-sessions.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
import { getDateWithoutDay } from "../../../util/config";
import GraphModal from "../workout-detail/graph-modal";
const style = {
  padding: "5px 0",
};
const TeamShootingSession = () => {
  const { selectedSport } = useContext(UserContext);
  const {
    teamSessionsData,
    setSelectedShooting,
    setShootingDetailModal,
    getShootingDetail,
    shootingDetailModal,
    teamSort,
    setTeamSort,
    listAllTitles,
    getTeamSessions,
    titlesList,
    selectedTeamSession,
    setSelectedTeamSession,
    hasMoreTeam,
    setTeamSessionsData,
    setTeamOffset,
    setSearchTitle,
    loading,
    setHasMoreTeam
  } = useContext(VisionShootingContext);
  let sessionOptions = [{ value: "all", label: "All Sessions" }];
  for (let i = 0; i < titlesList.length; i++) {
    sessionOptions.push({
      value: titlesList[i].title,
      label: titlesList[i].title,
    });
  }
  useEffect(() => {
    listAllTitles();
  }, []);
  useEffect(() => {
    getTeamSessions();
  }, [selectedTeamSession,teamSort]);
  return (
    <div>
      <div style={{ display: "flex", paddingBottom: 10 }}>
        <Select
          placeholder="All Sessions"
          onSearch={(e) => setSearchTitle(e)}
          // showSearch
          onChange={(e) => {setTeamOffset(0);setHasMoreTeam(true);setTeamSessionsData([]);setSelectedTeamSession(e)}}
          value={selectedTeamSession}
          style={{ marginLeft: "inherit" }}
          suffixIcon={<img src={DownArrow} />}
          className="select-input"
          popupClassName={
            "custom-action-select-dropdown " +
            (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")
          }
          options={sessionOptions}
        />
        <div style={{ marginLeft: "auto" }}>
          <span className="sorting">Sorting: </span>
          <Select
            placeholder="Newest"
            onChange={(e) => {
              setHasMoreTeam(true)
              setTeamOffset(0);
              setTeamSessionsData([]);
              setTeamSort(e);
            }}
            value={teamSort}
            style={{ marginLeft: "auto" }}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            options={[
              {
                value: "desc",
                label: "Newest",
              },
              {
                value: "asc",
                label: "Oldest",
              },
              {
                value: "highest",
                label: "Highest",
              },
              {
                value: "lowest",
                label: "Lowest",
              },
              {
                value: "az",
                label: "A-Z",
              },
              {
                value: "za",
                label: "Z-A",
              },
            ]}
          />
        </div>
      </div>
      {loading === true ? (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      ) : (
        <>
        {teamSessionsData.length > 1 && selectedTeamSession !== "all" &&
            <GraphModal graphData={teamSessionsData} selectedWorkout={selectedTeamSession} type="sessions"/>
          
        }
        <Row
          style={{ display: "flex" }}
          className="groups-row team-submission-video"
          gutter={[34, 34]}
        >
          <InfiniteScroll
            className="sessions-infinite-scroll"
            dataLength={teamSessionsData.length}
            next={getTeamSessions}
            width={400}
            hasMore={hasMoreTeam}
            loader={<h4 className="scroll-loading">Loading...</h4>}
          >
            {teamSessionsData?.length > 0
              ? teamSessionsData.map((item, index) => {
                  return (
                    <Col
                      className="gutter-row"
                      span={8}
                      style={{ paddingBottom: 16 }}
                      key={index}
                    >
                      <div style={style} className="div-1">
                        <Card
                          hoverable
                          className="video-card"
                          style={{
                            cursor:
                              item.media_url !== null ? "pointer" : "default",
                          }}
                          onClick={
                            item.media_url === null
                              ? null
                              : () => {
                                  setSelectedShooting(item);
                                  setShootingDetailModal(true);
                                  getShootingDetail(item.id);
                                }
                          }
                          cover={
                            <>
                              <div id="video-container">
                                {item?.media_thumbnail ? (
                                  <div
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                    }}
                                  >
                                    <img
                                      src={item.media_thumbnail}
                                      style={{ width: "100%", height: "164px" }}
                                    />
                                    <img
                                      src={Play}
                                      style={{
                                        width: 35,
                                        position: "absolute",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                      height: "100%",
                                    }}
                                  >
                                    <img
                                      src={Thumbnail}
                                      style={{ width: "40%", marginTop: "12%" }}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </>
                          }
                        >
                          <div className="date">
                            {" "}
                            <span
                              style={{ fontWeight: "bold", color: "#181c32" }}
                            >
                            {item.title !== "" ? item.title : "N/A"}
                            </span>
                          </div>
                          <div className="date">
                            Shots:{" "}
                            <span
                              style={{ fontWeight: "bold", color: "#181c32" }}
                            >
                              {item.total_make}/{item.total_attempts}({item.make_percentage}%)
                            </span>
                            
                          </div>
                          <div className="date">
                          Submitted at:{" "}
                          <span
                            style={{ fontWeight: "bold", color: "#181c32" }}
                          >
                            {getDateWithoutDay(item.created_at)}
                          </span>
                          </div>
                        </Card>
                      </div>
                    </Col>
                  );
                })
              : hasMoreTeam === false && (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ margin: "70px auto" }}
                  />
                )}
          </InfiniteScroll>
        </Row>
        </>
      )}
      {shootingDetailModal === true && <ShootingDetailModal />}
    </div>
  );
};

export default TeamShootingSession;
