import React, { useContext, useEffect } from "react";
import { Input, Modal, Form, Upload, Button, ColorPicker, message } from "antd";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import { SportContext } from "../../../context/setting-context/sports_context";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;

const EditSport = () => {
  const {darkButtonBackground , color , lightButtonBackground} = useContext(UserContext)
  const {
    addSport,
    selectedColor,
    selectedSport,
    setSelectedColor,
    editSportModal,
    setEditSportModal,
    imgSrc,
    setImgSrc,
    setImageUrl,
    updateSport,
  } = useContext(SportContext);

  const [form] = Form.useForm();

  const handleCancel = () => {
    setEditSportModal(false);
    form.resetFields();
    setImgSrc();
    setImageUrl();
    setSelectedColor("");
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    }
    else if(file.size <= 50000 || file.size > 10000000){
      message.error(`File size should be greater than 50KB or less than 10MB.`);
    }
    else{
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  }
  };
  const removeImage = () => {
    form.setFieldValue("avatar" , undefined)
    setImageUrl();
    setImgSrc();
  };
  useEffect(() => {
    if (!editSportModal) {
      handleCancel();
    }
  }, [editSportModal]);
  useEffect(() => {
    form.setFieldsValue({
      name: selectedSport.name,
      description:
        selectedSport.description !== "undefined"
          ? selectedSport.description
          : "",
      color: selectedSport.color,
      avatar:selectedSport.icon
    });
    setSelectedColor(selectedSport.color);
    setImgSrc(selectedSport.icon);
  }, [selectedSport]);
  return (
    <Modal
      wrapClassName={"add-sport-modal " + (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")}
      className="add-sport-modal"
      open={editSportModal}
      onOk={addSport}
      onCancel={handleCancel}
      okText={"Create"}
      cancelText={"Cancel"}
      width={"624px"}
      footer={false}
    >
      <div className="add-sport-main">
        <h1>Edit Sport</h1>
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="add-sport-form"
          onFinish={(e) => updateSport(e, selectedSport)}
        >
          <Form.Item name="avatar" label="Profile Photo" className="img-div">
            <div className="image-previewer">
              {!imgSrc ? (
                <Upload
                  className="uploader"
                  showUploadList={false}
                  type="file"
                  customRequest={dummyRequest}
                  beforeUpload={handleBeforeUpload}
                >
                  Upload pic
                </Upload>
              ) : (
                <>
                  <img
                    src={imgSrc}
                    alt="avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img"
                  />
                  <Upload
                    className="avatar-uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    <img src={Edit} alt="edit" className="edit"></img>
                  </Upload>
                  <img
                    src={Close}
                    alt="close"
                    className="delete"
                    onClick={removeImage}
                  />
                </>
              )}
            </div>
          </Form.Item>
          <span className="img-cond" style={{marginBottom:0}}>Allowed file types: PNG, JPG, JPEG.</span>
          <span className="img-type">Min Size:50KB Max Size:10MB</span>
          <Form.Item
            name="name"
            label="Sport Name"
            rules={[
              {
                required: true,
                message: "Please enter sport name",
              },
            ]}
          >
            <Input placeholder="Please enter sports name"/>
          </Form.Item>
          <Form.Item
            name="color"
            label="Select a color "
            rules={[
              {
                required: false,
              },
            ]}
          >
            <ColorPicker
              defaultValue={"#fff"}
              onChangeComplete={(color) => {
                setSelectedColor(color);
              }}
              showText={(color) => (
                <span className="custom-placeholder">
                  {" "}
                  {selectedColor && selectedColor !== ""
                    ? typeof selectedColor === "string"
                      ? selectedColor
                      : selectedColor.toHexString()
                    : " Pick a color or enter hex value e.g. #000000"}
                </span>
              )}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <TextArea rows={4} placeholder="Please enter description" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button type="default" onClick={() => handleCancel()} style={{ color:color , background : lightButtonBackground}}>
              Cancel
            </Button>
            <Button
              className="dark-base-btn"
              type="primary"
              style={{ marginInlineStart: 8 , background : darkButtonBackground }}
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditSport;
