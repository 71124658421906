import React, { useEffect, useState , useContext} from "react";
import { getDataTest, patchData, patchDataTest } from "../../util/api-calls";
import { message } from "antd";
import { UserContext } from "../user-context";
export const VisionShootingContext = React.createContext({});
export const VisionShootingContextProvider = ({ children = {} }) => {
  const { selectedNotification  } = useContext(UserContext);
  const [sessionsData, setSessionsData] = useState([]);
  const [selectedShooting, setSelectedShooting] = useState("");
  const [shootingDetailModal, setShootingDetailModal] = useState(false);
  const [shootingDetail, setShootingDetail] = useState({});
  const [shootingDetailView, setShootingDetailView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [ptr2Make, setPtr2Make] = useState(0);
  const [ptr3Make, setPtr3Make] = useState(0);
  const [sort, setSort] = useState("desc");
  const [editingKey, setEditingKey] = useState(false);
  // const [activeTab , setActiveTab] = useState("individual")
  const [loading, setLoading] = useState(false);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const getProfileSessions = () => {
    getDataTest(`vision/profile/${params.id}/session/list`)
      .then((res) => {
        setSessionsData(res.data.sessions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getShootingDetail = (id) => {
    getDataTest(`vision/profile/${params?.id}/session/${id}`)
      .then((res) => {
        setShootingDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProfileSessions();
  }, []);

  const updataVisions = () => {
    setLoading(true)
    var attempts = tableData.map(function (item, index) {
      return {
        score: item.score,
        make: item.make,
        zone:item.zone,
        shot_end_time: item.shot_end_time,
        shot_start_time: item.shot_start_time,
        timestamp: item.timestamp,
        id:item.id
      };
    });
    let data = {
      completion_time: shootingDetail.completion_time,
      total_attempts: shootingDetail.total_attempts,
      total_make: ptr2Make + ptr3Make,
      total_2ptr_attempts: shootingDetail.total_2ptr_attempts,
      total_2ptr_make: ptr2Make,
      total_3ptr_attempts: shootingDetail.total_3ptr_attempts,
      total_3ptr_make: ptr3Make,
      profile_id: shootingDetail.profile_id,
      media_uri: shootingDetail.media_uri,
      inaccurate_stats: shootingDetail.inaccurate_stats,
      attempts: attempts,
    };
    patchDataTest(`vision/session/${shootingDetail.id}`, data)
      .then((res) => {
        message.success({
          content:res.data.details
        })
        setEditingKey(false)
        // setShootingDetailModal(false)
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };
  useEffect(()=>{
    if(params.tab === "shooting_sessions"){
      getShootingDetail(selectedNotification?.session_id)
    }
  },[selectedNotification])
  const defaultContext = {
    sessionsData,
    setSelectedShooting,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    getShootingDetail,
    shootingDetail,
    selectedShooting,
    loading,
    setLoading,
    shootingDetailView,
    setShootingDetailView,
    sort,
    setSort,
    updataVisions,
    ptr2Make,
    setPtr2Make,
    ptr3Make,
    setPtr3Make,
    tableData,
    setTableData,
    params,
    editingKey,
    setEditingKey
    // activeTab,
    // setActiveTab
  };
  return (
    <VisionShootingContext.Provider value={defaultContext}>
      {children}
    </VisionShootingContext.Provider>
  );
};
