import React, { useContext, useState  , useRef, useEffect} from "react";
import { Button, Checkbox, Input, Modal, Radio, Select, Spin } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import { getDate } from "../../../util/config";
import Search from "../../../assets/images/search.svg";
import { getDataTraining } from "../../../util/api-calls";
import EditTask from "./edit-task-modal";
import CategoryModal from "../../Groups/existing-workout/category-modal";
import { UserContext } from "../../../context/user-context";
const ExistingTask = ({
  existingTaskModal,
  setExistingTaskModal,
  setNewTaskArray,
  newTaskArray,
  generalDetail
}) => {
  const {selectedSport} = useContext(UserContext)
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillLevel, setSkillLevel] = useState("beginner,intermediate,advanced");
  const [sort, setSort] = useState(true);
  const [taskType, setTaskType] = useState("ballogy");
  const [existingTasks, setExistingTasks] = useState([]);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [search , setSearch] = useState("")
  const [categoryModal , setCategoryModal] = useState(false)
  const inputRef = useRef(null);
  const getExistingTask = (e) => {
    setLoading(true);
    let url = ""
    if(skillLevel.length === 0){
      url = `admin/task?tag=${
      selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_desc=${sort}&search=${search}`
    }else{
      url = `admin/task?skill_level=${skillLevel}&tag=${
      selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_desc=${sort}&search=${search}`
    }
    getDataTraining(url)
      .then((res) => {
        setExistingTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOk = () => {
    setExistingTaskModal(false);
  };
  const handleCancel = () => {
    setExistingTaskModal(false);
  };
  React.useEffect(() => {
    if (existingTaskModal) {
      getExistingTask();
    } else {
      setSelectedCategory(null);
      setExistingTasks((existingTasks) => []);
      setTaskType(true);
      setSort(true);
      setSkillLevel(null);
    }
  }, [skillLevel, taskType, sort, selectedCategory, existingTaskModal , search]);
  const onChangeSkill = (e) => {
    setSkillLevel(e);
  };
  const onChangeTaskType = (e) => {
    setTaskType(e.target.value);
  };
  const onChangeCategory = (e) => {
    inputRef.current.focus({
      cursor: 'all',
    });
    setCategoryModal(true)
  };
  const clickedTask = (item) => {
    setEditTaskModal(true);
    setSelectedTask(item);
  };
  const searchTask= (e) => {
    if (e.target.value.length >= 3) {
      setExistingTasks([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setExistingTasks([]);
      setExistingTasks((existingTasks) => []);
      setSearch("");
    }
  };
  const options = [
    {
      value: "beginner",
      label: "Beginner",
    },
    {
      value: "intermediate",
      label: "Intermediate",
    },
    {
      value: "advanced",
      label: "Advanced",
    },
  ];
  const taskOptions = [
    {
      value: "ballogy",
      label: "Ballogy tasks",
    },
    {
      value: "group",
      label: "Only this group's tasks",
    },
    {
      value: "external",
      label: "External tasks",
    }
  ]
  return (
    <Modal
      className="task-modal"
      open={existingTaskModal}
      wrapClassName={selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Just This Assignment"}
      cancelText={"Update The Workout Template"}
      width={"699px"}
      footer={false}
    >
      <h1>Add Existing Task</h1>
      <p>Assign an existing task</p>
      <label htmlFor="category">Task category</label>
      <br />
      <Input
            placeholder="Select Category"
            optionFilterProp="children"
            onClick={onChangeCategory}
            suffixIcon={<img src={DownArrow} />}
            ref={inputRef}
            className="category-select search-select-input"
            style={{ height: 38 , width:413, color:"#7e8299" }}
            value={selectedCategory === "" ? "All Tasks" : selectedCategory}
      />
      <div className="tasks-list">
        <h3>Tasks List</h3>
        <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchTask}
            style={{ marginBottom: "26px",width:413 }}
          />
        <div style={{ display: "flex", marginBottom: "26px",marginTop:15 }}>
          {/* <Select
            placeholder="Skill Level"
            onChange={onChangeSkill}
            value={skillLevel}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            options={[
              {
                value: "beginner",
                label: "Beginner",
              },
              {
                value: "intermediate",
                label: "Intermediate",
              },
              {
                value: "advanced",
                label: "Advanced",
              },
            ]}
          />
          <Select
            placeholder="Select task type"
            onChange={onChangeTaskType}
            value={taskType}
            className="select-input"
            suffixIcon={<img src={DownArrow} />}
            options={[
              {
                value: "ballogy",
                label: "Ballogy tasks",
              },
              {
                value: "group",
                label: "Only this group's tasks",
              },
              {
                value: "external",
                label: "External tasks",
              }
            ]}
          /> */}
          {/* <Radio.Group options={taskOptions} onChange={onChangeTaskType} value={taskType} className="custom-radio" style={{marginTop:8}}/> */}
          <Checkbox.Group options={options} defaultValue={["beginner","intermediate","advanced"]} onChange={onChangeSkill} className="custom-checkbox"/>
          <Select
            placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
            style={{marginLeft:"auto"}}
            suffixIcon={<img src={DownArrow} />}
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            className="select-input"
            options={[
              {
                value: true,
                label: "Newest",
              },
              {
                value: false,
                label: "Oldest",
              },
            ]}
          />
        </div>
        {loading && loading == true ? (
          <Spin
            className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
            size="large"
            style={{
              position: "absolute",
              left: "49%",
              top:"90%"
            }}
          />
        ) : (
          <div className="task-listing">
            {existingTasks.map((item, index) => {
              let tags = item?.tags?.split(",");
              return (
                <div
                  className="list"
                  key={index}
                  onClick={() => clickedTask(item)}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <h3>{item.title}</h3>
                    <Button
                      className="green-tag"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.skill_level}
                    </Button>
                  </div>
                  <div className="task-added">
                    Added on:
                    <span className="time"> {getDate(item.created_at)} </span>by
                    <span className="name"> {item.group_name}</span>
                  </div>
                  <div className="tags">
                    Tags:
                    {tags?.map((item, index) => (
                      <Button className="purple-tag" key={index}>
                        {item}
                      </Button>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {editTaskModal === true && (
        <EditTask
          editTaskModal={editTaskModal}
          setEditTaskModal={setEditTaskModal}
          selectedTask={selectedTask}
          newTaskArray={newTaskArray}
          setNewTaskArray={setNewTaskArray}
          setExistingTaskModal={setExistingTaskModal}
          generalDetail={generalDetail}
        />
      )}
      <CategoryModal categoryModal={categoryModal} setCategoryModal={setCategoryModal} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} categoryType={"task"}/>
    </Modal>
  );
};

export default ExistingTask;
