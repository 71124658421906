import {
  Button,
  Modal,
  Spin,
  Col,
  Row,
  Table,
  Select,
  Input,
  TimePicker,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import Delete from "../../../assets/images/delete.svg";
import { PlusOutlined } from "@ant-design/icons";
import DownArrow from "../../../assets/images/arrows.svg";
import { UserContext } from "../../../context/user-context";
import Check from "../../../assets/images/check.svg";
import Cross from "../../../assets/images/cross.svg";
import dayjs from "dayjs";
import { VisionShootingContext } from "../../../context/user-context/vision-shooting-context";
import Chart from "../../shared/chart";
const style = {
  padding: "5px 0",
};
let isEditable;
let enableStart = 0;
let enableEnd = 0;
const ShootingDetailModal = () => {
  const {
    shootingDetail,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    updataVisions,
    ptr2Make,
    setPtr2Make,
    ptr3Make,
    setPtr3Make,
    tableData,
    setTableData,
    setLoading,
    loading,
    editingKey,
    setEditingKey,
    ptr2Attempts,
    pr2Make,
    setPr2Make,
    setPtr2Attempts,
    deleteVisionSession,
    setTotalMake,
    setTotalAttempts,
    ptr3Attempts,
    setPtr3Attempts,
    newTableData,
    setNewTableData,
  } = useContext(VisionShootingContext);
  const { selectedSport, color, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const zone = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  const point2Zone = ["F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
  const point3Zone = ["A", "B", "C", "D", "E"];
  const cancelModal = () => {
    setEditingKey(false);
    setTableData((tableData) => []);
    isEditable = undefined;
    setNewTableData((newTableData) => []);
    setShootingDetail({});
    setShootingDetailModal(false);
  };
  const zoneOptions = [];
  for (let i = 0; i < zone.length; i++) {
    zoneOptions.push({
      value: zone[i],
      label: zone[i],
    });
  }
  const [selectedZone, setSelectedZone] = useState();
  let updatedArray = newTableData?.filter(
    (item) => item.primary_action !== "added"
  );
  const updateMake = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== true) {
        if (!item.ptr && shootingDetail?.court === "NO_THREE_POINT_LINE") {
          setPtr2Make(ptr2Make + 1);
        } else if (
          !item.ptr &&
          shootingDetail?.court !== "NO_THREE_POINT_LINE"
        ) {
          if (point3Zone.includes(item.zone)) {
            setPtr3Make(ptr3Make + 1);
          } else {
            setPtr2Make(ptr2Make + 1);
          }
        } else if (item.ptr === 2) {
          setPtr2Make(ptr2Make + 1);
        } else {
          setPtr3Make(ptr3Make + 1);
        }
        return {
          ...item,
          make: true,
          score: 1,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    // setTableData(newArray);
    setNewTableData(newArray);
  };
  const edit = (record) => {
    // setPr2Make(shootingDetail?.total_2ptr_make);
    // setPtr2Attempts(shootingDetail?.total_2ptr_attempts);
    setEditingKey(true);
    if (tableData?.length === 0) {
      if (shootingDetail?.court === "NO_THREE_POINT_LINE") {
        setPtr2Attempts(ptr2Attempts + 1);
      } else {
        setPtr3Attempts(ptr3Attempts + 1);
      }
      const newObject = {
        // id: "",
        id: `temp_id_${Math.random().toString(36).substr(2, 9)}`,
        make: null,
        zone: shootingDetail?.court === "NO_THREE_POINT_LINE" ? "" : "A",
        score: 0,
        timestamp: new Date().toISOString(),
        shot_start_time: 0,
        shot_end_time: null,
      };
      setNewTableData((prevArray) => [...prevArray, newObject]); // add new object to the array
    }
  };
  const updateMiss = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== false) {
        if (item.make !== null) {
          if (!item.ptr && shootingDetail?.court === "NO_THREE_POINT_LINE") {
            setPtr2Make(ptr2Make === 0 ? 0 : ptr2Make - 1);
          } else if (
            !item.ptr &&
            shootingDetail?.court !== "NO_THREE_POINT_LINE"
          ) {
            if (point3Zone.includes(item.zone)) {
              setPtr3Make(ptr3Make === 0 ? 0 : ptr3Make - 1);
            } else {
              setPtr2Make(ptr2Make === 0 ? 0 : ptr2Make - 1);
            }
          }
          if (item.ptr === 2) {
            setPtr2Make(ptr2Make === 0 ? 0 : ptr2Make - 1);
          } else {
            setPtr3Make(ptr3Make === 0 ? 0 : ptr3Make - 1);
          }
        }
        return {
          ...item,
          make: false,
          score: 0,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    setNewTableData(newArray);
  };
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }
  function convertToMinuteSecond(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60); // Get full minutes
    const seconds = totalSeconds % 60; // Get remaining seconds
    return { minutes: minutes, seconds: seconds };
  }
  const updateZone = (record, zone, index) => {
    if (record.make !== null) {
      if (point3Zone.includes(record.zone) && point3Zone.includes(zone)) {
        console.log("no change");
      } else if (
        point2Zone.includes(record.zone) &&
        point2Zone.includes(zone)
      ) {
        console.log("no change");
      } else {
        if (point2Zone.includes(zone)) {
          if (record.make === true) {
            setPtr2Make(ptr2Make + 1);
            setPtr3Make(ptr3Make === 0 ? 0 : ptr3Make - 1);
          }
          setPtr2Attempts(ptr2Attempts + 1);
          setPtr3Attempts(ptr3Attempts === 0 ? 0 : ptr3Attempts - 1);
        } else if (point3Zone.includes(zone)) {
          if (record.make === true) {
            setPtr2Make(ptr2Make === 0 ? 0 : ptr2Make - 1);
            setPtr3Make(ptr3Make + 1);
          }
          setPtr2Attempts(ptr2Attempts === 0 ? 0 : ptr2Attempts - 1);
          setPtr3Attempts(ptr3Attempts + 1);
        }
      }
    }
    // if (record.id === "") {
    if (typeof record.id === "string" && record?.id.startsWith("temp_id")) {
      const updatedUsers = updatedArray.map((shot, i) =>
        i === index
          ? { ...shot, zone: zone, timestamp: new Date().toISOString() }
          : shot
      );
      setNewTableData(updatedUsers);
    } else {
      const newArray = updatedArray.map((item, i) => {
        if (record.id === item.id) {
          setSelectedZone(zone[record.id]);
          return {
            ...item,
            zone: zone,
            timestamp: new Date().toISOString(),
          };
        } else {
          return item;
        }
      });
      setNewTableData(newArray);
    }
  };
  const remove = (item, id) => {
    let point = item.make === true ? 1 : 0;
    if (item.ptr === 2) {
      setPtr2Attempts(ptr2Attempts - 1);
      setPtr2Make(ptr2Make - point);
    } else {
      setPtr3Attempts(ptr3Attempts - 1);
      setPtr3Make(ptr3Make - point);
    }
    setNewTableData((prevData) =>
      prevData.filter((row, index) => index !== id)
    );
  };
  const add = (item, index) => {
    enableStart = 0;
    enableEnd = 0;
    if (!item.ptr && shootingDetail?.court === "NO_THREE_POINT_LINE") {
      setPtr2Attempts(ptr2Attempts + 1);
    } else if (!item.ptr && shootingDetail?.court !== "NO_THREE_POINT_LINE") {
      setPtr3Attempts(ptr3Attempts + 1);
    } else if (item.ptr === 2) {
      setPtr2Attempts(ptr2Attempts + 1);
    } else {
      setPtr3Attempts(ptr3Attempts + 1);
    }
    const newObject = {
      ...item,
      // id:  ""
      id: `temp_id_${Math.random().toString(36).substr(2, 9)}`,
      make: null,
      zone:
        item.court === "NO_THREE_POINT_LINE" ? "" : item.zone === "" ? "" : "A",
      // zone: item.zone === "" ? "" : "A",
      timestamp: new Date().toISOString(),
      shot_start_time: item.shot_end_time,
      shot_end_time:
        tableData?.length === 0
          ? null
          : index + 1 === newTableData?.length &&
            item?.shot_start_time < shootingDetail?.video_duration * 1000
          ? item.shot_end_time
          : index + 1 === newTableData?.length &&
            item.shot_end_time === shootingDetail?.video_duration * 1000
          ? shootingDetail?.video_duration * 1000
          : null,
    };
    const newData = [
      ...newTableData.slice(0, index + 1), // Elements before the index+1
      newObject, // New object to add
      ...newTableData.slice(index + 1), // Elements after the +1index
    ];
    setNewTableData(newData);
  };
  const handleTimeChange = (value, field, rowIndex) => {
    const newData = [...newTableData];
    if(newTableData[rowIndex + 1] && rowIndex + 1 === newTableData?.length){
    newData[rowIndex + 1]["shot_end_time"] = value;
    }
    if(field === "shot_start_time" && (newTableData[rowIndex].shot_end_time % 60000) / 1000   < dayjs(value).$s ){
      newData[rowIndex]["shot_end_time"] = value;
    }
    newData[rowIndex][field] = value;
    // For second row onwards, set shot_start_time to the previous row's shot_end_time
    if (field === "shot_end_time") {
      const nextRow = newData[rowIndex + 1];
      if (nextRow) {
        nextRow.shot_start_time = value;
      }
    }
    setNewTableData(newData);
  };

  const shotStartDisableMinutesNew = (index) => {
    if (index === 0) return []; // No restriction for first row
    const prevRow = index > 0 ? newTableData[index - 1] : null;
    if (!prevRow || !prevRow.shot_end_time) return [];
    const prevEndMinutes = dayjs(prevRow.shot_end_time).$m;
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => minute < prevEndMinutes || minute > 16
    );
  };
  const shotStartDisabledSecondsNew = (record, index) => {
    if (index === 0) return []; // No restriction for first row

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    if (!prevRow || !prevRow.shot_end_time) return [];

    const prevEndMinutes = dayjs(prevRow.shot_end_time).$m;
    const prevEndSeconds = dayjs(prevRow.shot_end_time).$s;

    // Get the actual selected minute from shot_start_time
    const selectedMinute = record.shot_start_time
      ? dayjs(record.shot_start_time).$m
      : null;

    // If the selected minute is the same as the previous row's end minute, disable $sefore it
    if (selectedMinute !== null && selectedMinute === prevEndMinutes) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < prevEndSeconds + 1
      );
    }

    return [];
  };
  const shotStartDisableMinutes = (index) => {
    const allMinutes = Array.from({ length: 60 }, (_, minute) => minute); // Generate full minute range (0-59)
    const validMinutes = new Set(
      Array.from({ length: 17 }, (_, minute) => minute)
    ); // Only allow 0-16

    if (index === 0)
      return allMinutes.filter((minute) => !validMinutes.has(minute)); // Disable all above 16

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    const nextRow =
      index < newTableData.length - 1 ? newTableData[index + 1] : null;

    if (!prevRow || !prevRow.shot_end_time) {
      return allMinutes.filter((minute) => !validMinutes.has(minute)); // Ensure only 0-16 are allowed
    }

    const prevEndMinutes = dayjs(prevRow.shot_end_time).minute();
    const nextStartMinutes = nextRow
      ? dayjs(nextRow.shot_start_time).minute()
      : null;
    if (
      prevEndMinutes ===
      convertToMinuteSecond(shootingDetail?.video_duration).minutes
    ) {
      return allMinutes.filter(
        (minute) =>
          !validMinutes.has(minute) || // Disable all minutes above 16
          minute < prevEndMinutes || // Disable minutes before prev end time
          (nextStartMinutes !== null && minute > nextStartMinutes) || // Disable minutes after next start time
          (prevEndMinutes ===
            convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes)
      );
    } else {
      return allMinutes.filter(
        (minute) =>
          !validMinutes.has(minute) || // Disable all minutes above 16
          minute < prevEndMinutes || // Disable minutes before prev end time
          (nextStartMinutes !== null && minute > nextStartMinutes) || // Disable minutes after next start time
          (prevEndMinutes !==
            convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes)
      );
    }
  };
  const shotStartDisabledSeconds = (record, index) => {
    if (index === 0) return []; // No restriction for first row

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    const nextRow =
      index < newTableData.length - 1 ? newTableData[index + 1] : null;
    const selectedMinute = record.shot_start_time
      ? dayjs(record.shot_start_time).$m
      : null;
    if (
      !prevRow ||
      !prevRow.shot_end_time ||
      !record.shot_start_time ||
      !record.shot_end_time
    )
      return [];

    const prevEndTime = dayjs(prevRow.shot_end_time);
    const startTime = dayjs(record.shot_start_time);
    const endTime = dayjs(record.shot_end_time);

    const prevEndMinutes = prevEndTime.minute();
    const prevEndSeconds = prevEndTime.second();

    const startMinutes = startTime.minute();
    const startSeconds = startTime.second();

    const endMinutes = endTime.minute();
    const endSeconds = endTime.second();

    let disabledSecs = [];
    if (index + 1 === newTableData?.length) {
      if (
        prevEndMinutes <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
        selectedMinute <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) => sec < prevEndSeconds
        );
      } else if (
        prevEndMinutes <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
        selectedMinute ===
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec < prevEndSeconds ||
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      }
    } else {
      // If shot_start_time is in the same minute as prevRow's shot_end_time, disable seconds before prevEndSeconds
      if (startMinutes === prevEndMinutes) {
        disabledSecs = [
          ...disabledSecs,
          ...Array.from({ length: prevEndSeconds }, (_, sec) => sec),
        ];
      }

      // If shot_end_time is in the same minute as shot_start_time, disable seconds after endSeconds
      if (startMinutes === endMinutes) {
        disabledSecs = [
          ...disabledSecs,
          ...Array.from(
            { length: 60 - endSeconds - 1 },
            (_, sec) => sec + endSeconds + 1
          ),
        ];
      }

      // If shot_start_time and shot_end_time are in different minutes
      if (startMinutes !== endMinutes) {
        // In the shot_start_time minute, disable seconds before startSeconds
        if (selectedMinute === startMinutes) {
          disabledSecs = [
            ...disabledSecs,
            ...Array.from({ length: startSeconds }, (_, sec) => sec),
          ];
        }

        // In the shot_end_time minute, disable seconds after endSeconds
        if (selectedMinute === endMinutes) {
          disabledSecs = [
            ...disabledSecs,
            ...Array.from(
              { length: 60 - endSeconds - 1 },
              (_, sec) => sec + endSeconds + 1
            ),
          ];
        }
      }

      return disabledSecs;
    }
  };

  const shotEndDisabledMinutes = (record, index) => {
    const startTime = record.shot_start_time;
    if (!startTime)
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (m) => m > 16
      );

    const startMinutes = dayjs(startTime).minute();
    // const currentMinute = dayjs(record.shot_end_time).minute();
    var currentMinute =
      record?.shot_end_time === null
        ? null
        : typeof record.shot_end_time === "number"
        ? Math.floor(record.shot_end_time / 60000)
        : dayjs(record.shot_end_time).minute();
    var currentSeconds = ((record.shot_end_time % 60000) / 1000).toFixed(0);

    // Get the next row's shot_end_time minute
    const nextRow = tableData[index + 1];
    let nextRowEndMinute = 16; // Default max (16) if next row doesn't exist

    if (nextRow && nextRow.shot_end_time) {
      nextRowEndMinute = Math.min(dayjs(nextRow.shot_end_time).minute(), 16); // Ensure it's within range
    }

    // Disable minutes greater than next row's shot_end_time minute or outside 0-16 range
    if (nextRow) {
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (minute) =>
          minute < startMinutes || minute > nextRowEndMinute || minute > 16
      );
    } else {
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (minute) =>
          minute < startMinutes ||
          (minute > currentMinute &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes) ||
          minute > 16
      );
    }
  };
  const shotEndDisabledSeconds = (record, index) => {
    const startTime = record.shot_start_time;
    if (!startTime) return [];

    const startMinutes = dayjs(startTime).minute();
    const startSeconds = dayjs(startTime).second();
    const selectedTime = record.shot_end_time;
    const selectedMinutes = dayjs(selectedTime).minute();
    const selectedSeconds = dayjs(selectedTime).second();

    // **Get the next row's shot_end_time**
    const nextRow = tableData[index];
    let nextRowEndMinute = null;
    let nextRowEndSeconds = null;

    if (nextRow && nextRow.shot_end_time) {
      const nextEndTime = dayjs(nextRow.shot_end_time);
      nextRowEndMinute = nextEndTime.minute();
      nextRowEndSeconds = nextEndTime.second();
    }

    // let enableStart = startSeconds;
    // let enableEnd = 0;

    enableStart = startSeconds;
    enableEnd = 0;
    // If the selected minute is the start minute, restrict seconds
    if (selectedMinutes === startMinutes) {
      enableEnd = 59;
    }
    // if(selectedMinutes === startMinutes){
    //   if(selectedSeconds !== 0){
    //     enableEnd = selectedSeconds
    //   }else if(selectedSeconds === 0){
    //     enableEnd = 59
    //   }
    //   else{
    //     enableEnd = convertToMinuteSecond(shootingDetail?.video_duration).seconds
    //   }
    // }
    if (typeof record.id !== "string") {
      // if(!record?.id.startsWith("temp_id")){
      enableStart = parseInt((record.shot_start_time % 60000) / 1000);
      enableEnd = parseInt((record.shot_end_time % 60000) / 1000);
    }

    // If the selected minute matches the next row’s `shot_end_time` minute, disable all seconds before `nextRowEndSeconds`
    if (selectedMinutes === nextRowEndMinute) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) =>
          sec >= nextRowEndSeconds + 1 ||
          sec < enableStart ||
          sec > enableEnd + 1
      );
    }
    if (index + 1 === newTableData?.length) {
      if (selectedMinutes > startMinutes) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else if (
        selectedMinutes === startMinutes &&
        startMinutes ===
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec < enableStart ||
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) => sec < enableStart || sec > enableEnd
        );
      }
    } else {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < enableStart || (sec > enableEnd || sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds)
      );
    }
  };

  const shotEndDisabledMinutesNew = (record) => {
    const startTime = record.shot_start_time;
    // if (!startTime) return [];

    const startMinutes = dayjs(startTime).minute();

    // Create an array of all 60 minutes and filter out those we want to disable
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => minute < startMinutes || minute > 16 // Ensure minutes are between 0 and 16
    );
  };
  const shotEndDisabledSecondsNew = (record) => {
    const startTime = record.shot_start_time;
    if (!startTime) return [];
    const startMinutes = dayjs(startTime).$m;
    const startSeconds = dayjs(startTime).$s;
    const selectedMinute = record.shot_end_time
      ? dayjs(record.shot_end_time).$m
      : null;
    if (selectedMinute !== null && selectedMinute === startMinutes) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < startSeconds + 1
      );
    }
    return [];
  };
  const columns = [
    {
      title: "Shot count",
      dataIndex: "count",
      key: "count",
      render: (text, record, index) => {
        // isEditable = index > 0 && newTableData[index - 1].id === "";
        isEditable =
          index > 0 &&
          typeof newTableData[index - 1]?.id === "string" &&
          newTableData[index - 1]?.id.startsWith("temp_id");
        const nextShot =
          index > 0 &&
          newTableData[index - 1].shot_end_time === null &&
          newTableData[index];
        return (
          <div key={index}>
            <span className="span-1">{index + 1}</span>
            {(typeof record.id === "string" &&
              record?.id.startsWith("temp_id")) ||
            isEditable ? (
              // {record.id === "" || isEditable ? (
              <div
                style={{
                  fontSize: 14,
                  color: "A1A5B7",
                  fontWeight: 600,
                  marginTop: 5,
                }}
              >
                <div style={{ display: "flex", marginBottom: 5 }}>
                  <span style={{ padding: "8px 10px 8px 0px", width: 38 }}>
                    Start:
                  </span>
                  <TimePicker
                    value={
                      record?.shot_start_time !== null &&
                      dayjs(record.shot_start_time)
                    }
                    hideDisabledOptions
                    defaultValue={dayjs("00:00:00", "mm:ss")}
                    allowClear={false}
                    onCalendarChange={(value) => {
                      handleTimeChange(value, "shot_start_time", index);
                      if (
                        shotStartDisabledSeconds(record, index).includes(
                          value?.$s
                        ) &&
                        tableData?.length !== 0
                      ) {
                        handleTimeChange(
                          value.second(0),
                          "shot_start_time",
                          index
                        );
                      }
                    }}
                    onChange={(value) => {
                      if (value) {
                        const updatedValue = value.second(0); // Reset the seconds to 0
                        handleTimeChange(
                          updatedValue,
                          "shot_start_time",
                          index
                        );
                      }
                    }}
                    style={{ width: 100 }}
                    format="mm:ss"
                    minuteStep={1}
                    secondStep={1}
                    showNow={false}
                    needConfirm={false}
                    suffixIcon={false}
                    disabled={
                      index + 1 !== newTableData?.length &&
                      tableData?.length !== 0
                        ? (typeof record.id === "string" &&
                            record?.id.startsWith("temp_id")) ||
                          nextShot !== false
                        : // ? record.id === "" || nextShot !== false
                          record?.shot_start_time === 0
                    }
                    disabledTime={() => ({
                      disabledMinutes:
                        // () =>
                        tableData?.length === 0
                          ? () => shotStartDisableMinutesNew(index)
                          : () => shotStartDisableMinutes(index),
                      disabledSeconds:
                        tableData?.length === 0
                          ? () => shotStartDisabledSecondsNew(record, index)
                          : () => shotStartDisabledSeconds(record, index),
                    })}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <span style={{ padding: "8px 10px 8px 0px", width: 38 }}>
                    End:
                  </span>

                  <TimePicker
                    value={
                      record?.shot_end_time !== null
                        ? dayjs(record.shot_end_time)
                        : null
                    }
                    hideDisabledOptions
                    allowClear={false}
                    onCalendarChange={(value) => {
                      handleTimeChange(value, "shot_end_time", index);
                      if (
                        shotEndDisabledSeconds(record, index).includes(
                          value?.$s
                        ) &&
                        tableData?.length !== 0
                      ) {
                        handleTimeChange(
                          value.second(0),
                          "shot_end_time",
                          index
                        );
                      }
                    }}
                    disabled={
                      // index + 1 === newTableData?.length ||
                      record?.shot_end_time ===
                        shootingDetail?.video_duration * 1000 ||
                      (tableData?.length !== 0 && isEditable && newTableData?.length !== index + 1)
                    }
                    style={{ width: 100 }}
                    showNow={false}
                    needConfirm={false}
                    suffixIcon={false}
                    onChange={(value) => {
                      if (value) {
                        const updatedValue = value.second(0); // Reset the seconds to 0
                        handleTimeChange(updatedValue, "shot_end_time", index);
                      }
                    }}
                    format="mm:ss"
                    minuteStep={1}
                    secondStep={1}
                    disabledTime={() => ({
                      disabledMinutes:
                        tableData?.length === 0
                          ? () => shotEndDisabledMinutesNew(record)
                          : () => shotEndDisabledMinutes(record, index),
                      disabledSeconds:
                        tableData?.length === 0
                          ? () => shotEndDisabledSecondsNew(record)
                          : () => shotEndDisabledSeconds(record, index),
                    })}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: 14,
                  color: "A1A5B7",
                  fontWeight: 600,
                  marginTop: 5,
                }}
              >
                Start:
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  {record?.shot_start_time?.$m || record?.shot_start_time?.$s
                    ? dayjs(record.shot_start_time).$m +
                      ":" +
                      dayjs(record.shot_start_time).$s
                    : millisToMinutesAndSeconds(record.shot_start_time)}
                </span>
                , End:
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  {record?.shot_end_time?.$m || record?.shot_end_time?.$s
                    ? dayjs(record.shot_end_time).$m +
                      ":" +
                      dayjs(record.shot_end_time).$s
                    : millisToMinutesAndSeconds(record.shot_end_time)}
                  {/* {millisToMinutesAndSeconds(record.shot_end_time)} */}
                </span>
              </div>
            )}
          </div>
        );
      },
      // },
    },
    {
      title: "zone",
      dataIndex: "zone",
      key: "zone",
      render: (text, record, index) => {
        return editingKey === false ? (
          <div key={index}>
            <span className="span-1">{record.zone}</span>
          </div>
        ) : (
          <Select
            placeholder="Newly Added"
            optionFilterProp="children"
            onChange={(e) => updateZone(record, e, index)}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            defaultValue={record.zone}
            value={selectedZone}
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            disabled={record.make === null}
            options={zoneOptions}
          />
        );
      },
    },
    {
      title: "recorded result",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => {
        return editingKey ? (
          <div style={{ display: "flex", gap: "10px" }} className="records">
            <Button
              className={
                record.make === false || record.make === null
                  ? "grey-btn-disable"
                  : "green-btn"
              }
              onClick={() => {
                updateMake(index);
              }}
            >
              Make
            </Button>
            <Button
              className={
                record.make === true || record.make === null
                  ? "grey-btn-disable"
                  : "red-btn"
              }
              onClick={() => updateMiss(index)}
            >
              Miss
            </Button>
          </div>
        ) : (
          <span
            style={{ display: "inline", justifyContent: "center" }}
            className={record.make === true ? "green-tag" : "red-tag"}
          >
            {record.make === true ? "Make" : "Miss"}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
            <img src={Delete} onClick={() => remove(record, index)} />
            <PlusOutlined
              // disabled={ newTableData.some((item) => item.shot_start_time === null||item.shot_end_time === null)}
              disabled={
                record?.shot_start_time === null ||
                record?.shot_end_time === null ||
                (tableData?.length !== 0 &&
                  index > 0 &&
                  index + 1 !== newTableData?.length &&
                  typeof newTableData[index]?.id === "string" &&
                  newTableData[index]?.id.startsWith("temp_id"))
                //  || index > 0 && newTableData[index].id === ""
              }
              style={{
                strokeWidth: 30,
                stroke: "#7e8299",
                fontSize: "large",
                cursor:
                  record?.shot_start_time === null ||
                  record?.shot_end_time === null ||
                  (tableData?.length !== 0 &&
                    index > 0 &&
                    index + 1 !== newTableData?.length &&
                    typeof newTableData[index]?.id === "string" &&
                    newTableData[index]?.id.startsWith("temp_id"))
                    ? //  || index > 0 && newTableData[index].id === ""
                      "not-allowed"
                    : "pointer",
              }}
              onClick={() =>
                record?.shot_start_time === null ||
                record?.shot_end_time === null ||
                (tableData?.length !== 0 &&
                  index > 0 &&
                  index + 1 !== newTableData?.length &&
                  typeof newTableData[index]?.id === "string" &&
                  newTableData[index]?.id.startsWith("temp_id"))
                  ? //  || index > 0 && newTableData[index].id === ""
                    false
                  : add(record, index)
              }
            />
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (Object.keys(shootingDetail).length > 0) {
      setTotalAttempts(shootingDetail?.total_attempts);
      setTotalMake(shootingDetail?.total_make);
      setTableData(shootingDetail?.attempts);
      setNewTableData(shootingDetail?.attempts);
      setPtr2Make(shootingDetail?.total_2ptr_make);
      setPtr2Attempts(shootingDetail?.total_2ptr_attempts);
      setPtr3Attempts(shootingDetail?.total_3ptr_attempts);
      setPtr3Make(shootingDetail?.total_3ptr_make);
    }
  }, [shootingDetail]);
  return (
    <Modal
      className="shooting-detail-modal"
      rootClassName={
        "shooting-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={shootingDetailModal}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(shootingDetail).length > 0 ? (
        <div>
          <div style={{ display: "flex" }}>
            {shootingDetail?.profile?.avatar === null ? (
              <Avatar
                name={shootingDetail?.profile?.name}
                color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                maxInitials={2}
                className="profile-avatar"
              />
            ) : (
              <img
                src={shootingDetail?.profile?.avatar}
                className="profile-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            )}
            <div className="name-div">
              <h3 className="user-name">{shootingDetail?.profile?.name}</h3>
              <h4 className="user-handle">
                @{shootingDetail?.profile?.username}
              </h4>
            </div>
            <div style={{ display: "flex", marginLeft: "auto" }}>
              <div className="submitted-date">
                <h3 className="user-name">Submitted</h3>
                <h4 className="user-handle">
                  {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                </h4>
              </div>
              {
                // shootingDetail.court !== "NO_THREE_POINT_LINE" &&
                editingKey === false && (
                  <Button
                    className="grey-btn"
                    onClick={() => {
                      edit();
                    }}
                  >
                    Edit
                  </Button>
                )
              }
            </div>
          </div>
          {shootingDetail?.media_url !== null && (
            <ReactPlayer
              url={shootingDetail?.media_url}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
            />
          )}
          <h2>Your Shots</h2>
          <Row gutter={16} className="score-row">
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {ptr2Make + ptr3Make} / {ptr2Attempts + ptr3Attempts}(
                  {isNaN(
                    Math.round((ptr2Make + ptr3Make) * 100) /
                      (ptr2Attempts + ptr3Attempts)
                  )
                    ? 0
                    : (
                        Math.round((ptr2Make + ptr3Make) * 100) /
                        (ptr2Attempts + ptr3Attempts)
                      ).toFixed(1)}
                  %)
                </p>
                <span>Overall</span>
              </div>
            </Col>
            {/* {editingKey === true && shootingDetail?.court === "NO_THREE_POINT_LINE" ? 
            <Col className="gutter-row" span={8}>
            <div style={style} className="dotted-box">
              {" "}
              <p>
                <Input value={ptr2Make} onChange={(e)=>{
                  setPtr2Make(e.target.value)
                  setPr2Make(e.target.value)}} style={{width:50,height:32}} />/
                <Input value={ptr2Attempts} onChange={(e)=>setPtr2Attempts(e.target.value)} style={{width:50,height:32}} />
                (
                {isNaN(
                  Math.round(pr2Make * 100) /
                  ptr2Attempts
                ) || ptr2Attempts === 0 || ptr2Attempts === "0" || ptr2Attempts?.length === 0?  0 :  (
                  Math.round(pr2Make * 100) /
                  ptr2Attempts
                ).toFixed(1)}
                %)
              </p>
              <span>2PT FG%</span>
            </div>
          </Col>
            : */}
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {ptr2Make}/{ptr2Attempts}(
                  {isNaN(Math.round(ptr2Make * 100) / ptr2Attempts)
                    ? 0
                    : (Math.round(ptr2Make * 100) / ptr2Attempts).toFixed(1)}
                  %)
                </p>
                <span>2PT FG%</span>
              </div>
            </Col>
            {/* } */}
            {shootingDetail.court !== "NO_THREE_POINT_LINE" && (
              <Col className="gutter-row" span={8}>
                <div style={style} className="dotted-box">
                  {" "}
                  <p>
                    {ptr3Make}/{ptr3Attempts}(
                    {isNaN(Math.round(ptr3Make * 100) / ptr3Attempts)
                      ? 0
                      : (Math.round(ptr3Make * 100) / ptr3Attempts).toFixed(1)}
                    %)
                  </p>
                  <span>3PT FG%</span>
                </div>
              </Col>
            )}
          </Row>
          <Table
            rowKey="id"
            // rowKey={() => Math.random().toString(36).substr(2, 9)} // Generate a random key
            pagination={false}
            columns={
              editingKey && shootingDetail?.court === "NO_THREE_POINT_LINE"
                ? columns.filter((column) => column.key !== "zone")
                : !editingKey && shootingDetail?.court === "NO_THREE_POINT_LINE"
                ? columns.filter(
                    (column) => column.key !== "zone" && column.key !== "action"
                  )
                : !editingKey
                ? columns.filter((column) => column.key !== "action")
                : columns
            }
            dataSource={updatedArray}
            className={
              "table-container " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme") +
              (shootingDetail?.court === "NO_THREE_POINT_LINE"
                ? " alignment"
                : "")
            }
            scroll={{
              y: 55 * 5,
            }}
          />
          {shootingDetail.court !== "NO_THREE_POINT_LINE" && (
            <div style={{ margin: "50px 0" }}>
              <Chart
                setLoading={setLoading}
                shotChart={shootingDetail}
                edit={editingKey}
              />
            </div>
          )}
          {editingKey === true && (
            <div style={{ float: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "10px 8px",
                  background: darkButtonBackground,
                  height: 40,
                  border: "none",
                  boxShadow: "none",
                  color: "#fff",
                }}
                loading={loading}
                disabled={
                  loading ||
                  newTableData.some(
                    (item) =>
                      item.make === null ||
                      item.shot_start_time === null ||
                      item.shot_end_time === null
                  )
                }
                onClick={updataVisions}
                className="dark-base-btn"
              >
                Save
              </Button>
              <Button
                className="grey-btn"
                onClick={() => {
                  isEditable = undefined;
                  setNewTableData(tableData);
                  setEditingKey(false);
                  setPtr2Make(shootingDetail?.total_2ptr_make);
                  setPtr2Attempts(shootingDetail?.total_2ptr_attempts);
                  setPtr3Attempts(shootingDetail?.total_3ptr_attempts);
                  setPtr3Make(shootingDetail?.total_3ptr_make);
                }}
                style={{
                  margin: "10px 8px",
                  height: 40,
                  width: 85,
                  border: "none",
                  boxShadow: "none",
                  float: "left",
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      )}
      {editingKey === false && (
        <Button
          className="grey-btn"
          style={{ marginTop: 20 }}
          onClick={() => deleteVisionSession(shootingDetail?.id)}
        >
          Delete session
        </Button>
      )}
    </Modal>
  );
};
export default ShootingDetailModal;
