import React, { useContext, useState, Fragment, useEffect } from "react";
import "./shot-chart.scss";
import { Input, Button, Col, Row, Divider } from "antd";
import {
  ShotChartContextProvider,
  ShotChartContext,
} from "../../../coach-context/group-context/shot-chart-context";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { UserContext } from "../../../context/user-context";
import Chart from "../../../admin-module/shared/chart";
TimeAgo.addDefaultLocale(en);
const { TextArea } = Input;
const style = {
  padding: "5px 0",
};
const ShotChartPage = () => {
  const { color, lightButtonBackground, selectedSport } =
    useContext(UserContext);
  const { shotChart, setLoading } = useContext(ShotChartContext);

  return (
    <div className="shot-chart-main">
      <div style={{ display: "flex", marginBottom: 32 }}>
        <h1>Shot Chart</h1>
      </div>
      <Chart shotChart={shotChart} setLoading={setLoading} edit={false} />
      <Row gutter={16} className="score-row">
        <Col className="gutter-row" span={8}>
          <h2>Last 7 Days</h2>
          <div style={style} className="dotted-box">
            <p>
              {isNaN(
                Math.round(shotChart.last_7_days_total_make * 100) /
                  shotChart.last_7_days_total_attempts
              )
                ? 0
                : (
                    Math.round(shotChart.last_7_days_total_make * 100) /
                    shotChart.last_7_days_total_attempts
                  ).toFixed(1)}%
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_7_days_total_make}
                </span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_7_days_total_attempts}
                </span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <h2>Last 30 Days</h2>
          <div style={style} className="dotted-box">
            {" "}
            <p>
              {isNaN(
                Math.round(shotChart.last_30_days_total_make * 100) /
                  shotChart.last_30_days_total_attempts
              )
                ? 0
                : (
                    Math.round(shotChart.last_30_days_total_make * 100) /
                    shotChart.last_30_days_total_attempts
                  ).toFixed(1)}%
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_30_days_total_make}
                </span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_30_days_total_attempts}
                </span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <h2>Last 6 Months</h2>
          <div style={style} className="dotted-box">
            {" "}
            <p>
              {isNaN(
                Math.round(shotChart.last_6_month_total_make * 100) /
                  shotChart.last_6_month_total_attempts
              )
                ? 0
                : (
                    Math.round(shotChart.last_6_month_total_make * 100) /
                    shotChart.last_6_month_total_attempts
                  ).toFixed(1)}%
            </p>
            <Divider />
            <Row>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_6_month_total_make}
                </span>
                <span className="heading">Shots Made</span>
              </Col>
              <Col span={12}>
                <span className="count">
                  {shotChart.last_6_month_total_attempts}
                </span>
                <span className="heading">Shots Attempted</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ShotChart = () => {
  return (
    <ShotChartContextProvider>
      <ShotChartPage />
    </ShotChartContextProvider>
  );
};
export default ShotChart;
