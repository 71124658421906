import React, { useContext, useState } from "react";
import {
  Table,
  Tabs,
  Input,
  Button,
  Select,
  DatePicker,
  Dropdown,
  message,
  Tooltip,
} from "antd";
import "./submission-table.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
import { useNavigate } from "react-router-dom";
import { getDateWithoutDay, secondsToTime } from "../../../util/config";
import DownArrow from "../../../assets/images/arrows.svg";
import Calendar from "../../../assets/images/calendar.svg";
import RowVertical from "../../../assets/images/row-vertical.svg";
import Filter from "../../../assets/images/filter.svg";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../context/group-context/group-context";
import Instructions from "../instructions/instructions";
import {
  SubmissionContext,
  SubmissionContextProvider,
} from "../../../context/submission-context/bsa_submission_context";
import AssigneeModal from "../submission-detail/assignee-modal";
import { UserContext } from "../../../context/user-context";
import PendingUploads from "../pending-uploads/pending-uploads";
const SubmissionsTablePage = ({ activeTab }) => {
  const dateFormat = "ddd. MMMM D, YYYY";
  const { accountDetail, selectedSport, color } = useContext(UserContext);
  const {
    submissionsList,
    assignmentsList,
    setAssignmentsList,
    setStatus,
    status,
    loading,
    setSort,
    setSubmissionsList,
    setSearch,
    setStartDate,
    setAssignmentStartDate,
    setAssignmentEndDate,
    setEndDate,
    assigneeModal,
    setAssigneeModal,
    setSelectedReview,
    selectedReview,
    showAssignments,
    setShowAssignmemts,
    setAssignmentSort,
    setAssignmentSearch,
    setUploadSort,
    setUploadSearch,
    setUploadStatus,
    setDraft,
  } = useContext(SubmissionContext);
  const { pageNumber, pageSize, groups, totalGroups, handleTableChange } =
    useContext(GroupContext);
  const navigate = useNavigate();
  const onChange = (key) => {
    if (key !== "assignments") {
      setAssignmentSearch("");
      setAssignmentStartDate("");
      setAssignmentEndDate("");
      setAssignmentSort("");
    }
    if (key === "pending") {
      setShowAssignmemts(false);
      setStatus("pending");
      setAssignmentsList((assignmentsList) => []);
    } else if (key === "completed") {
      setShowAssignmemts(false);
      setStatus("final");
      setAssignmentsList((assignmentsList) => []);
    } else if (key === "uploads") {
      setDraft(false);
      setUploadSearch("");
      setUploadSort(true);
      setUploadStatus("");
      setShowAssignmemts(false);
      setStatus("uploads");
      setAssignmentsList((assignmentsList) => []);
    } else if (key === "assignments") {
      setShowAssignmemts(true);
      setStatus("");
      setSearch("");
      setStartDate("");
      setEndDate("");
      setSort("");
    } else {
      setShowAssignmemts(false);
      setAssignmentsList((assignmentsList) => []);
      setStatus("all");
    }
  };
  const subItems = [
    {
      key: "all",
      label: `All`,
    },
    {
      key: "pending",
      label: `Pending`,
    },
    {
      key: "completed",
      label: `Completed`,
    },
    {
      key: "uploads",
      label: "Uploads in Progress",
      children: <PendingUploads />,
    },
    {
      key: "assignments",
      label: `My Assignments`,
      disabled: accountDetail.is_superuser === true,
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { RangePicker } = DatePicker;
  const onChangeSelect = (value) => {};
  const items = [
    {
      key: "details",
      label: "View Details",
    },
    {
      key: "assign_to",
      label: "Assign To",
      disabled: showAssignments,
    },
  ];
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   render:(text , record) => <span>{record.athlete?.username}</span>

    // },
    {
      title: "Player name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text, record) => <span>{record.athlete?.username}</span>,
    },
    {
      title: "Submission date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{getDateWithoutDay(record.created)}</span>
      ),
    },
    {
      title: "Video duration",
      dataIndex: "video_duration",
      key: "video_duration",
      render: (text, record) => (
        <span>{secondsToTime(record.video_duration)}</span>
      ),
    },
    {
      title: "SUBMISSION STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div>
            {record.status === "pending" ? (
              <span
                className="purple-tag"
                style={{ textTransform: "capitalize", marginLeft: 0 }}
              >
                Ready for Review
              </span>
            ) : record.status === "rejected" ? (
              <span className="red-tag" style={{ textTransform: "capitalize" }}>
                {record.status}
              </span>
            ) : record.status === "review" ? (
              <span
                className="yellow-tag"
                style={{ textTransform: "capitalize" }}
              >
                Transcoding
              </span>
            ) : (
              <span className="green-tag">Completed</span>
            )}
            {record?.athlete?.id === "940" && (
              <Tooltip
                title="He hasn’t used his code yet"
              >
                {" "}
                <ExclamationCircleTwoTone
                  twoToneColor={"#F6C000"}
                  style={{ fontSize: 15 , marginLeft:10}}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Assigned to",
      dataIndex: "assigned",
      key: "assigned",
      render: (text, record) => (
        <span>
          {record.assigned_person
            ? record.assigned_person?.profile.username
            : "N/A"}
        </span>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      width: 200,
      elipsis: true,
      render: (text, record, key) => {
        return (
          <Dropdown
            placeholder={
              record.status === "final"
                ? "Review Locked"
                : record.status === "rejected"
                ? "Review Rejected"
                : "Actions"
            }
            className={
              "custom-dropdown " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
            disabled={
              record.status === "final" ||
              record.status === "rejected" ||
              record.status === "review"
            }
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "details") {
                  if (
                    accountDetail.id !== record.assigned_person?.id &&
                    !accountDetail.is_superuser
                    //  accountDetail.role?.role_permission.filter(
                    //   (e) =>
                    //     e.module.name === "Submissions" &&
                    //     e.permission.code_name === "REVIEW"
                    // ).length === 0
                  ) {
                    info();
                  } else {
                    navigate({
                      pathname: `./bsa-detail/${record.athlete?.username}`,
                      search: `id=${record.id}&tabs=${activeTab}`,
                    });
                  }
                } else {
                  if (accountDetail.role !== null) {
                    for (let item of accountDetail.role?.role_permission) {
                      if (
                        item.module.name === "Submissions" &&
                        item.permission.code_name === "ASSIGNMENT"
                      ) {
                        if (item.value === false) {
                          info();
                        } else if (
                          accountDetail.role &&
                          record.assigned_person.id === accountDetail.id
                        ) {
                          info();
                        } else {
                          setAssigneeModal(true);
                          setSelectedReview(record);
                        }
                      }
                    }
                  } else {
                    setAssigneeModal(true);
                    setSelectedReview(record);
                  }
                }
              },
            }}
            overlayClassName={
              "submission-dropdown-menu custom-dropdown-menu " +
              (selectedSport === 1
                ? "orange-theme-dropdown"
                : "blue-theme-dropdown")
            }
          >
            <a onClick={(e) => e.preventDefault()}>
              {record.status === "final" ? (
                "Review Locked"
              ) : record.status === "rejected" ? (
                "Rejected"
              ) : (
                <>
                  Actions <img src={DownArrow} />
                </>
              )}
            </a>
          </Dropdown>
        );
      },
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const searchVideo = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1)
      setSubmissionsList([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setSubmissionsList((submissionsList) => []);
      setSearch("");
    }
  };
  const searchAssignmentVideo = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1)
      setAssignmentsList([]);
      setAssignmentSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAssignmentsList((assignmentsList) => []);
      setAssignmentSearch("");
    }
  };
  const changeRangePicker = (e) => {
    if (e) {
      setStartDate(new Date(e[0]).toISOString());
      setEndDate(new Date(e[1]).toISOString());
    } else {
      setStartDate("");
      setEndDate("");
    }
  };
  const changeAssignmentRangePicker = (e) => {
    if (e) {
      setAssignmentStartDate(new Date(e[0]).toISOString());
      setAssignmentEndDate(new Date(e[1]).toISOString());
    } else {
      setAssignmentStartDate("");
      setAssignmentEndDate("");
    }
  };
  return (
    <div className="submission-table-main">
      <Tabs
        defaultActiveKey="1"
        items={subItems}
        onChange={onChange}
        className={
          "custom-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")
        }
        destroyInactiveTabPane
      />
      {status !== "uploads" && (
        <div className="table-div">
          {showAssignments === true ? (
            <div style={{ display: "flex" }}>
              <Input
                key={"assignment"}
                addonBefore={<img src={Search} />}
                placeholder="Search"
                className="search-input"
                onChange={searchAssignmentVideo}
              />
              <div className="right-div">
                <Select
                  key={"assignment_select"}
                  placeholder="Newest"
                  optionFilterProp="children"
                  suffixIcon={<img src={DownArrow} />}
                  className={
                    "custom-action-select " +
                    (selectedSport === 1
                      ? "orange-theme-select"
                      : "blue-theme-select")
                  }
                  popupClassName={
                    "custom-action-select-dropdown " +
                    (selectedSport === 1
                      ? "orange-theme-select"
                      : "blue-theme-select")
                  }
                  onChange={(e) => setAssignmentSort(e)}
                  options={[
                    {
                      value: true,
                      label: "Newest",
                    },
                    {
                      value: false,
                      label: "Oldest",
                    },
                  ]}
                />
                <RangePicker
                  key={"assignment_picker"}
                  className="range-picker"
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  onChange={changeAssignmentRangePicker}
                />
                {/* <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button> */}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <Input
                addonBefore={<img src={Search} />}
                placeholder="Search"
                className="search-input"
                onChange={searchVideo}
              />
              <div className="right-div">
                <Select
                  placeholder="Newest"
                  optionFilterProp="children"
                  suffixIcon={<img src={DownArrow} />}
                  className="select-input"
                  popupClassName={
                    "custom-action-select-dropdown " +
                    (selectedSport === 1
                      ? "orange-theme-select"
                      : "blue-theme-select")
                  }
                  onChange={(e) => setSort(e)}
                  options={[
                    {
                      value: true,
                      label: "Newest",
                    },
                    {
                      value: false,
                      label: "Oldest",
                    },
                  ]}
                />
                <RangePicker
                  className="range-picker"
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  onChange={changeRangePicker}
                />
                {/* <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button> */}
              </div>
            </div>
          )}
          <Table
            onChange={handleTableChange}
            rowKey={(obj) => obj.id}
            // rowSelection={rowSelection}
            pagination={{
              pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
              defaultPageSize: 10,
              current: pageNumber,
              pageSize: pageSize,
              total: totalGroups,
            }}
            loading={loading}
            columns={columns}
            // dataSource={data}
            dataSource={
              showAssignments === true ? assignmentsList : submissionsList
            }
            className={
              "table-container " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
          />
        </div>
      )}
      <AssigneeModal
        assigneeModal={assigneeModal}
        setAssigneeModal={setAssigneeModal}
        selectedTest={selectedReview}
      />
    </div>
  );
};

const SubmissionsTable = ({ activeTab }) => {
  return (
    <SubmissionContextProvider>
      <SubmissionsTablePage activeTab={activeTab} />
    </SubmissionContextProvider>
  );
};
export default SubmissionsTable;
