import React, { useContext, useEffect, useState } from "react";
import { deleteItemTraining, getDataTraining } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../user-context";
import { message } from "antd";
export const FeaturedWorkoutContext = React.createContext({});
export const FeaturedWorkoutContextProvider = ({ children = {} }) => {
  const { selectedSport } = useContext(UserContext);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [sort, setSort] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allFeaturedWorkouts, setAllFeaturedWorkouts] = useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalWorkouts, setTotalWorkouts] = useState(0);
  const [type , setType] = useState("")
  const [pageNumber , setPageNumber] = useState(1)
  const getAllFeatureWorkouts = () => {
    setLoading(true);
    getDataTraining(
      `admin/all/feature/workout?sports_id=${selectedSport}&search=${search}&order_desc=${sort}&start_date=${startDate}&end_date=${endDate}&offset=${offset}&limit=${limit}&fw_type=${type}`
    )
      .then((res) => {
        setAllFeaturedWorkouts(res.data.results);
        setTotalWorkouts(res.data.total_count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteFeatureWorkout = (id) => {
    deleteItemTraining(`feature/workout/${id}`)
    .then((res)=>{
      message.success({
        content:res?.data?.details
      })
      getAllFeatureWorkouts();
    }).catch((err)=>{
      message.error({
        content:err.response.data.message
      })
    })
  }
  useEffect(() => {
    if (selectedSport) {
      getAllFeatureWorkouts();
    }
  }, [sort, search, selectedSport, startDate, endDate, offset , type]);
  const defaultContext = {
    allFeaturedWorkouts,
    loading,
    params,
    sort,
    setSort,
    setSearch,
    setStartDate,
    setEndDate,
    totalWorkouts,
    limit,
    offset,
    setAllFeaturedWorkouts,
    setOffset,
    setType,
    pageNumber,
    setPageNumber,
    setOffset,
    setLimit,
    deleteFeatureWorkout
  };
  return (
    <FeaturedWorkoutContext.Provider value={defaultContext}>
      {children}
    </FeaturedWorkoutContext.Provider>
  );
};
