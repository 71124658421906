import { Button, Modal, Spin, Col, Row, Input, Select } from "antd";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import { CloseOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/user-context";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import Chart from "../../shared/chart";
const style = {
  padding: "5px 0",
};
const ShootingDetailModal = () => {
  const wrapperRef = useRef(null);
  const {
    shootingDetail,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    setLoading,
    activeTab,
    deleteTeamSession,
    reviewSession,
    updateSession,
    sessionTitle,
    setSessionTitle,
    titleUpdate,
    setTitleUpdate,
    setTeamOffset,
    getSuggestionsList,
    suggestionsList,
    searchTitle,
    setSearchTitle,
    showDropdown,
    setShowDropdown,
    setSuggestionsList,
    removeSuggestion,
    titleError,
    setTitleError
  } = useContext(VisionShootingContext);
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const cancelModal = () => {
    setSearchTitle("");
    setShowDropdown(false);
    sessionOptions = []
    setSuggestionsList([]);
    setShootingDetail({});
    setShootingDetailModal(false);
  };
  useEffect(() => {
    setSearchTitle(shootingDetail?.title);
    setSessionTitle(shootingDetail?.title);
    if (Object.keys(shootingDetail).length > 0 && activeTab === "individual") {
      reviewSession();
    }
  }, [shootingDetail]);
  let sessionOptions = [];
  for (let i = 0; i < suggestionsList.length; i++) {
    sessionOptions.push({
      value: suggestionsList[i].id,
      label: suggestionsList[i].title,
    });
  }
  const changeTitleInput = (e) => {
    setSessionTitle(e.target.value);
    setSearchTitle(e.target.value);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  useEffect(() => {
    if(suggestionsList?.length === 0 || searchTitle.length === 0){
      setSuggestionsList([]);
      sessionOptions = []
      setShowDropdown(false);
    }
    if (searchTitle === "" ) {
      setSearchTitle("");
      setShowDropdown(false);
      sessionOptions = []
      setSuggestionsList([]);
    } else if (
      sessionTitle?.length > 2 &&
      searchTitle !== shootingDetail?.title
    ) {
      setSuggestionsList([]);
      sessionOptions = []
      const delayDebounceFn = setTimeout(() => {
        getSuggestionsList();
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    } else {
      setShowDropdown(false);
      sessionOptions = []
      setSuggestionsList([]);
    }
  }, [searchTitle]);
  return (
    <Modal
      className="shooting-detail-modal"
      rootClassName={
        "shooting-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={shootingDetailModal}
      // onOk={handleOk}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(shootingDetail).length > 0 ? (
        <div>
          {activeTab === "teams" ? (
            <>
              <div style={{ display: "flex" }}>
                <h1>Vision Team Shooting Results</h1>
                <div style={{ marginLeft: "auto" }} className="submitted-date">
                  <h3 className="user-name">Submitted</h3>
                  <h4 className="user-handle">
                    {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                  </h4>
                </div>
              </div>
              <div
                style={{ display: "flex", marginBottom: 20 }}
                className="title-div"
              >
                <div className="title">Session Title</div>
                {/* <Select
                  showSearch
                  style={{
                    width: 260,
                    height: 48,
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  defaultValue={sessionTitle}
                  value={sessionTitle}
                  onInputKeyDown={(e) => {
                    setSessionTitle(e.target.value);
                    setTeamOffset(0);
                    setTitleUpdate(true);
                  }}
                  onSearch={(e)=>{setSessionTitle(e);setTeamOffset(0);setTitleUpdate(true)}}
                  onChange={(e)=>{setSessionTitle(e);setTeamOffset(0);setTitleUpdate(true)}}
                  options={sessionOptions}
                /> */}
                <div className="title-input" ref={wrapperRef}>
                  <Input
                    defaultValue={sessionTitle}
                    value={sessionTitle}
                    onChange={(e) => {
                      setSessionTitle(e.target.value);
                      setTitleError(false)
                      setTeamOffset(0);
                      setTitleUpdate(true);
                      changeTitleInput(e);
                    }}
                  />
                  {titleError === true && 
                  <span className="error-message" style={{marginTop:0}}>Please enter session title</span>
                  }
                  {showDropdown && sessionOptions?.length > 0 && (
                    <div className="suggestions-dropdown">
                      {sessionOptions && sessionOptions.length > 0 && (
                        <Fragment>
                          {sessionOptions.map((suggestion, index) => (
                            <div
                              key={"suggestion_" + index}
                              className="suggestion-option"
                              style={{ display: "flex" }}
                            >
                              <div
                                onClick={() => {
                                  setTitleError(false)
                                  setSessionTitle(suggestion.label);
                                  setShowDropdown(false);
                                }}
                                style={{ width: "90%" }}
                              >
                                {" "}
                                {suggestion.label}
                              </div>
                              <span
                                style={{ textAlign: "end", width: "10%" }}
                                onClick={() =>
                                  removeSuggestion(suggestion.value)
                                }
                              >
                                <CloseOutlined
                                  onClick={() =>
                                    removeSuggestion(suggestion.value)
                                  }
                                />
                              </span>
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
                {titleUpdate === true && (
                  <>
                    <Button
                      className="dark-base-btn"
                      style={{ background: darkButtonBackground }}
                      onClick={() => updateSession(true)}
                    >
                      Keep
                    </Button>
                    <Button
                      className="gray-base-btn"
                      onClick={() => {
                        setTitleUpdate(false);
                        setSessionTitle(shootingDetail?.title);
                      }}
                    >
                      Revert
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : (
            <div style={{ display: "flex" }}>
              {shootingDetail?.profile?.avatar === null ? (
                <Avatar
                  name={shootingDetail?.profile?.name}
                  color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                  maxInitials={2}
                  className="profile-avatar"
                />
              ) : (
                <img
                  src={shootingDetail?.profile?.avatar}
                  className="profile-logo"
                  style={
                    imageLoaded === ""
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  onLoad={() => setImageLoaded("loaded")}
                />
              )}
              <div className="name-div">
                <h3 className="user-name">{shootingDetail?.profile?.name}</h3>
                <h4 className="user-handle">
                  @{shootingDetail?.profile?.username}
                </h4>
              </div>
              <div style={{ marginLeft: "auto" }} className="submitted-date">
                <h3 className="user-name">Submitted</h3>
                <h4 className="user-handle">
                  {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                </h4>
              </div>
            </div>
          )}
          {shootingDetail?.media_url !== null && (
            <ReactPlayer
              url={shootingDetail?.media_url}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
            />
          )}
          <h2>Your Shots</h2>
          <Row gutter={16} className="score-row">
            <Col className="gutter-row" span={8}>
              <div style={style} className="dotted-box">
                {" "}
                <p>
                  {shootingDetail.total_make}/{shootingDetail.total_attempts} (
                  {isNaN(
                    Math.round(shootingDetail.total_make * 100) /
                      shootingDetail.total_attempts
                  )
                    ? 0
                    : (
                        Math.round(shootingDetail.total_make * 100) /
                        shootingDetail.total_attempts
                      ).toFixed(1)}
                  %)
                </p>
                <span>Overall</span>
              </div>
            </Col>
            {activeTab !== "teams" && (
              <>
                <Col className="gutter-row" span={8}>
                  <div style={style} className="dotted-box">
                    {" "}
                    <p>
                      {shootingDetail.total_2ptr_make}/
                      {shootingDetail.total_2ptr_attempts}(
                      {isNaN(
                        Math.round(shootingDetail.total_2ptr_make * 100) /
                          shootingDetail.total_2ptr_attempts
                      )
                        ? 0
                        : (
                            Math.round(shootingDetail.total_2ptr_make * 100) /
                            shootingDetail.total_2ptr_attempts
                          ).toFixed(1)}
                      %)
                    </p>
                    <span>2PT FG%</span>
                  </div>
                </Col>
                {shootingDetail.court !== "NO_THREE_POINT_LINE" && (
                  <Col className="gutter-row" span={8}>
                    <div style={style} className="dotted-box">
                      {" "}
                      <p>
                        {shootingDetail.total_3ptr_make}/
                        {shootingDetail.total_3ptr_attempts}(
                        {isNaN(
                          Math.round(shootingDetail.total_3ptr_make * 100) /
                            shootingDetail.total_3ptr_attempts
                        )
                          ? 0
                          : (
                              Math.round(shootingDetail.total_3ptr_make * 100) /
                              shootingDetail.total_3ptr_attempts
                            ).toFixed(1)}
                        %)
                      </p>
                      <span>3PT FG%</span>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
          {activeTab === "individual" && (
            // shootingDetail.court !== "NO_THREE_POINT_LINE" &&
            <Chart setLoading={setLoading} shotChart={shootingDetail} edit={false}/>
          )}
        </div>
      ) : (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      )}
      {activeTab === "teams" && (
        // shootingDetail.court !== "NO_THREE_POINT_LINE" &&
        <Button
          className="grey-btn"
          onClick={() => deleteTeamSession(shootingDetail?.id)}
        >
          Delete session
        </Button>
      )}
    </Modal>
  );
};
export default ShootingDetailModal;
