import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Radio,
  Upload,
  DatePicker,
  Spin,
  Space,
  message,
} from "antd";
// import "./create-new-group.scss";
import "./account-settings.scss";
import RoleIcon from "../../../assets/images/security.svg";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import Calendar from "../../../assets/images/calendar.svg";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import {
  AccountSettingsContext,
  AccountSettingsContextProvider,
} from "../../../context/setting-context/account_setting_context";
import dayjs from "dayjs";
import { UserContext } from "../../../context/user-context";
import ChangePassword from "./change-password-modal";
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const formItemLayout1 = {
  labelCol: {
    span: 15,
  },
  wrapperCol: {
    span: 15,
  },
};
const AccountSettingsPage = () => {
  const {selectedSport , color , lightButtonBackground} = useContext(UserContext)
  const {
    gender,
    setImageUrl,
    setGender,
    imgSrc,
    setImgSrc,
    loading,
    accountDetail,
    setChangePasswordModal
  } = useContext(AccountSettingsContext);
  // const {subStart , subExpire , newSubscription,subscriptionStatus , setNewSubscription,subscriptionHistory,setSubscriptionHistory,setSubscriptionStatus} = useContext(SubscriptionContext)
  const [form] = Form.useForm();
  const onChangeGender = (e) => {
    setGender(e.target.value);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    }
    else if(file.size <= 50000 || file.size > 10000000){
      message.error(`File size should be greater than 50KB or less than 10MB.`);
    }
    else{
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    console.log(file);
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  }
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  useEffect(() => {
    form.setFieldsValue({
      name: accountDetail.first_name !== null ? accountDetail.first_name : "" + " " + accountDetail.last_name !== null ?  accountDetail.last_name : "",
      birth_date: dayjs(accountDetail.profile?.date_of_birth),
      gender: accountDetail.profile?.gender,
      username: accountDetail.profile?.username,
      email: accountDetail.email,
      phone: accountDetail.phone,
    });
  }, [accountDetail]);
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="account-setting-main">
          <Form
            form={form}
            name="dynamic_rule"
            className="account-setting-form"
            // onFinish={createGroup}
            initialValues={{}}
          >
            <Form.Item
              {...formItemLayout}
              name="avatar"
              label="Display Photo"
              className="img-div"
            >
              <div className="image-previewer">
                {!imgSrc ? (
                  <Upload
                    className="uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    Upload pic
                  </Upload>
                ) : (
                  <>
                    <img
                      src={imgSrc}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      className="img"
                    />
                    <Upload
                      className="avatar-uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}
                    >
                      <img src={Edit} alt="edit" className="edit"></img>
                    </Upload>
                    <img
                      src={Close}
                      alt="close"
                      className="delete"
                      onClick={removeImage}
                    />
                  </>
                )}
              </div>
              <span className="img-type" style={{marginBottom:0}}>
                Allowed file types: PNG, JPG, JPEG.
              </span>
              <span className="img-type">Min Size:50KB Max Size:10MB</span>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="name"
              label="Full Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter full name.",
                },
              ]}
            >
              <Input
                placeholder="Please enter full name"
                className="custom-input"
                disabled
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="birth_date"
              label="Date of Birth"
              rules={[
                {
                  required: false,
                  message: "Please input your name",
                },
              ]}
            >
              <DatePicker
                className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
                rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
                format={dateFormat}
                suffixIcon={<img src={Calendar} />}
                style={{ height: "48px" }}
                disabled
              />
            </Form.Item>
            <Form.Item {...formItemLayout} name="gender" label="Gender">
              <Radio.Group
                onChange={onChangeGender}
                value={gender}
                className="custom-radio"
                disabled
              >
                <Radio value={"male"}>Male</Radio>
                <Radio value={"female"}>Female</Radio>
                <Radio value={"undisclosed"}>Undisclosed</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="role"
              label="Role"
              className="role"
            >
              <img src={RoleIcon} />
              <span style={{textTransform:"capitalize"}}>{ accountDetail.is_superuser === true ? "Super Admin" : accountDetail.role?.role_name } </span>
            </Form.Item>
            <h2>Account</h2>
            <Form.Item
              {...formItemLayout}
              name="username"
              label="Username"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter user name.",
                },
              ]}
            >
              <Input
                placeholder="Please enter user name"
                className="custom-input"
                disabled
              />
            </Form.Item>
            <div style={{ display: "flex" }}>
              <Form.Item
                {...formItemLayout}
                style={{ width: "100%" }}
                name="email"
                label="Email"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter email"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
              {/* <span style={{ display: "flex", margin: "14px 12px" }}>
                {" "}
                <CheckCircleTwoTone
                  twoToneColor="#50CD89"
                  style={{ fontSize: 20, paddingRight: 10, display: "block" }}
                />{" "}
                Verified
              </span> */}
            </div>
            <div style={{ display: "flex" }}>
              <Form.Item
                label="Phone Number"
                name="phone"
                style={{ width: "100%" }}
                className="name-row"
                {...formItemLayout}
              >
                <Input
                  placeholder="Please enter phone number"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
              {/* <span style={{ display: "flex", margin: "14px 12px" }}>
                {" "}
                <ExclamationCircleTwoTone
                  twoToneColor="#F6C000"
                  style={{ fontSize: 20, paddingRight: 10, display: "block" }}
                />{" "}
                Verified
              </span> */}
            </div>
            <Form.Item {...formItemLayout} label="Change Password ">
              <Button className="red-base-btn" style={{ marginLeft: 0  ,
              color: color,
              background: lightButtonBackground,
              marginLeft: 0,
            }} onClick={()=>setChangePasswordModal(true)}>
                Change Password
              </Button>
            </Form.Item>
            {/* <Form.Item {...formItemLayout} label="Delete Account">
              <Button className="delete-btn">Delete Account</Button>
            </Form.Item> */}
          </Form>
        </div>
      )}
      <ChangePassword />
    </>
  );
};

const AccountSettings = () => {
  return (
    <AccountSettingsContextProvider>
      <AccountSettingsPage />
    </AccountSettingsContextProvider>
  );
};
export default AccountSettings;
