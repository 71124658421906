import React, { useContext, useRef, useEffect, useState } from "react";
import { Input, Modal, Form, Select, Button, Empty } from "antd";
import ApexCharts, { ApexOptions } from "apexcharts";
import DownArrow from "../../../assets/images/arrows.svg";
import { AdminUserContext } from "../../../context/setting-context/admin_user_context";
import { graphDate } from "../../../util/config";
import { WorkoutDetailContext } from "../../../context/workouts-context/workout-detail-context";
let chartColor = "",
  chartHeight = "";
const style = {
  padding: "5px 0",
};
let score = [];
let date = [];
let sum = 0;
let averageScore = [];

const GraphModal = ({ graphModal, setGraphModal , graphData , selectedWorkout , type}) => {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  // const { graphData } = useContext(WorkoutDetailContext);
  const handleCancel = () => {
    setGraphModal(false);
  };
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current !== null 
      // && graphModal === true
      ) {
      var chart = new ApexCharts(
        chartRef.current,
        getChartOptions(chartColor, chartHeight)
      );
      chart.render();

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [chartRef, graphModal , data , category , graphData]);
  useEffect(() => {
    sum = 0
    setData(data=>[])
    setCategory(category=>[])  
    date = [];
    score = [];
    averageScore = [];
    graphData?.map((item) => {
    date.push(graphDate(item?.created_at));
    if(type === "sessions"){
      score.push(item?.make_percentage ? item?.make_percentage : 0)
    }else{
      score.push(item?.score ? item.score : 0);
    } 
    });
    selectedWorkout?.is_fw === true && graphData?.map((item) => {
      if( item?.is_reviewed === true && item?.is_validated !== false && item?.is_failed === false){
        if(type === "sessions"){
          averageScore.push(item?.make_percentage ? item.make_percentage : 0);
        }else{
        averageScore.push(item?.score ? item.score : 0);
        }

      }
    });
    setCategory(date);
    setData(score);
    const arr = selectedWorkout?.is_fw === true ? averageScore : score;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i]/arr.length;
    }
    // if(graphModal === false){
    //   sum = 0
    // }
  }, [graphData]);
  function getChartOptions(height) {
    const labelColor = "#7E8299";
    const borderColor = "#E1E3EA";
    const baseColor = "#50CD89";
    const lightColor = "#50CD89";

    return {
      series: [
        {
          name: type === "sessions" ? "Shooting%" : "Score",
          data: data,
        },
      ],
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 273,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },

      fill: {
        type: "gradient",
        opacity: 0.9,
        gradient: {
          type: "vertical",
          shadeIntensity: 1,
        },
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 2,
        colors: [baseColor],
      },
      xaxis: {
        categories: category,
        min: 0,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        title: {
          text: "Submission Date",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#a1a5b7",
            fontSize: "14px",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-title",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        min: 0,
        // max: 50,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        title: {
          text: type === "sessions" ? "Shooting%" : "Score",
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#a1a5b7",
            fontSize: "14px",
            fontWeight: 500,
            cssClass: "apexcharts-yaxis-title",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        show: false,
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 3,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: baseColor,
        strokeWidth: 3,
      },
    };
  }
  return (
    <div className="submission-graph">
      {type !== "sessions" &&<h1>Workout Progress</h1>}
      <div style={style} className="div-1">
        {graphData?.length > 0 ? (
          <>
            <h2>Average  {type === "sessions" ? "Shooting%" :"Score"}</h2>
            <div className="score" style={{ marginBottom: 35 }}>
              {(Math.round(sum * 100) / 100).toFixed(1)}
            </div>
            {/* {graphModal === true && ( */}
              <div
                ref={chartRef}
                className="mixed-widget-5-chart card-rounded-top"
              ></div>
            {/* )} */}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ marginBlock: 70 }}
          />
        )}
      </div>
      </div>
  );
};

export default GraphModal;
