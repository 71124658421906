import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Button,
  Input,
  Checkbox,
  Radio,
  Select,
  Upload,
  DatePicker,
  Divider,
  Spin,
  InputNumber,
  message,
} from "antd";
import "./create-profile.scss";
import Edit from "../../../assets/images/edit.svg";
import Close from "../../../assets/images/close.svg";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import Calendar from "../../../assets/images/calendar.svg";
import { useNavigate } from "react-router-dom";
// import {
//   CreateProfileContext,
//   CreateProfileContextProvider,
// } from "../../../coach-context/user-context/user-context";
import { CreateProfileContext, CreateProfileContextProvider } from "../../../coach-context/create-profile-context";
import DownArrow from "../../../assets/images/arrows.svg";
import dayjs from "dayjs";
import { patchData } from "../../../util/coach-api-calls";
import { UserContext } from "../../../context/user-context";
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const CreateProfilePage = () => {
  const {darkButtonBackground , selectedSport} = useContext(UserContext)
  const {
    createProfile,
    type,
    setImageUrl,
    imgSrc,
    setImgSrc,
    loading,
    sportsList,
    setSport,
    profileType,
    setProfileType,
    gender,
    setGender,
    shootingHand,
    setShootingHand,
    imageUpload,
    setImageUpload,
    nameError,
    setUsername,
    profileData,
    setPositionPf,
    setPositionPg,
    setPositionSf,
    setPositionSg,
    setPositionC,
    setFeet,
    setInch,
    feet, inch, positionPg, positionSg, positionSf, positionC, positionPf, imageUrl,
    updateProfile, editForm, setEditForm
  } = useContext(CreateProfileContext);
  const navigate = useNavigate();
  const [bsaCredit, setBsaCredit] = useState();
  const [newSport, setNewSport] = useState(false);
  const [form] = Form.useForm();
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    }
    else if(file.size <= 50000 || file.size > 10000000){
      message.error(`File size should be greater than 50KB or less than 10MB.`);
    }
    else{
    setImageUpload(false);
    setImageUrl(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImgSrc(reader.result);
    };
    reader.readAsDataURL(file);
    // Prevent upload
    return false;
  }
  };
  let sportOption = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOption.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      setImgSrc(profileData.avatar);
      form.setFieldsValue({
        avatar: profileData.avatar,
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        username: profileData.username,
        dob: dayjs(profileData.date_of_birth),
        weight_lbs: profileData.weight !== null && profileData.weight,
        location: profileData.location,
        shooting_hand: profileData.shooting_hand,
        gender: profileData.gender,
        school: profileData.school,
        job_title: profileData.job_title,
        years_experience: profileData.years_experience,
        profile_type: profileData.profile_type,
        height_feet:
          profileData.height !== null && profileData.height !== "" ?
            parseInt(profileData.height.split("′")[0])
            : null,
        height_inch:
          profileData?.height !== null && profileData.height !== "" ?
            parseInt(
              profileData.height.substring(
                profileData.height.indexOf("′") + 1,
                profileData.height.lastIndexOf("″")
              )
            ) : null,
      });
      setFeet(
        profileData?.height !== null ? profileData.height.split("′")[0] : null
      );
      setInch(
        profileData?.height !== null ?
          profileData.height.substring(
            profileData.height.indexOf("′") + 1,
            profileData.height.lastIndexOf("″")
          ) : null
      );
      console.log(profileData)
      setProfileType(profileData.profile_type);
      setShootingHand(profileData.shooting_hand);
      setGender(profileData.gender);
      setPositionPg(profileData.position_pg);
      setPositionPf(profileData.position_pf);
      setPositionSf(profileData.position_sf);
      setPositionSg(profileData.position_sg);
      setPositionC(profileData.position_c);
    }
  }, [profileData])
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-profile-main">
          <div className="heading-row">
            {/* <img
              src={LeftArrow} className= "left-arrow"
              alt=""
              onClick={() => navigate("/home/users")}
            /> */}
            <h1>{editForm === false ? "Profile Information" : "Edit Profile"}</h1>
            {editForm === false &&
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "0 8px",
                  marginLeft: "auto",
                  background : darkButtonBackground
                }}
                className="dark-base-btn"
                onClick={() => setEditForm(true)}
              >
                Edit
              </Button>
            }
          </div>
          {editForm === false ?
            <Form
              form={form}
              name="dynamic_rule"
              className="profile-form"
              style={{ padding: "22px 30px" }}
            >
              <Form.Item
                {...formItemLayout}
                name="avatar"
                label="Display Photo"
                className="img-div"
              >
                <div className="image-previewer">
                  {!imgSrc ? (
                    <Upload
                      className="uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}
                      disabled
                    >
                      Upload pic
                    </Upload>
                  ) : (
                    <>
                      <img
                        src={imgSrc}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        className="img"
                      />
                      <Upload
                        className="avatar-uploader"
                        showUploadList={false}
                        type="file"
                        customRequest={dummyRequest}
                        beforeUpload={handleBeforeUpload}
                        disabled
                      >
                      </Upload>
                    </>
                  )}
                </div>

                <span className="img-type" style={{marginBottom:0}}>
                  Allowed file types: PNG, JPG, JPEG.
                </span>
                <span className="img-type">Min Size:50KB Max Size:10MB</span>
                {imageUpload === true ? (
                  <span className="error-message" style={{ color: "red" }}>
                    Please upload image
                  </span>
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="profile_type"
                label="Profile Type"
                className="shooting-div"
              >
                <Radio.Group
                  onChange={(e) => setProfileType(e.target.value)}
                  value={profileType}
                  // defaultValue={"player"}
                  className="custom-radio"
                  disabled
                >
                  <Radio value={"player"}>Player</Radio>
                  <Radio value={"coach"}>Coach</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="first_name"
                label="First Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input first name"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="last_name"
                label="Last Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input last name"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="username"
                label="User Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter username.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input username"
                  className="custom-input"
                  disabled
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="dob"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please input date of birth",
                  },
                ]}
              >
                <DatePicker
                  className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
                  rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
                  // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  style={{ height: "48px" }}
                  disabled
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="gender"
                label="Gender"
                className="gender-div"
                rules={[
                  {
                    required: false,
                    message: "Please input your name",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  disabled
                  // defaultValue={"male"}
                  // defaultChecked={"male"}

                  className="custom-radio"
                >
                  <Radio value={"male"}>Male</Radio>
                  <Radio value={"female"}>Female</Radio>
                  <Radio value={"undisclosed"}>Undisclosed</Radio>
                </Radio.Group>
              </Form.Item>
              {profileType === "player" ? (
                <>
                  <Form.Item
                    {...formItemLayout}
                    label="Measurements"
                    className="measurements-input"
                  >
                    <div style={{ display: "flex", gap: 8 }}>
                      <label style={{ padding: "15px 0" }}>Height:</label>
                      <Form.Item
                        name="height_feet"
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            max: 8,
                            pattern: new RegExp("^[0-9]*$"),
                            message: "Feet must be between ${min} to ${max}.",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={"ft"}
                          placeholder="Feet"
                          type="number"
                          value={feet}
                          disabled
                          onKeyDown={(e) => e.key === "." && e.preventDefault()}
                        />
                      </Form.Item>
                      <Form.Item
                        name="height_inch"
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            max: 11,
                            message: "Inches must be between ${min} to ${max}.",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={"in"}
                          placeholder="Inch"
                          type="number"
                          value={inch}
                          disabled
                          onKeyDown={(e) => e.key === "." && e.preventDefault()}
                        />
                      </Form.Item>
                      {/* <Form.Item
                  name="height_dec"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      max: 75,
                      message: "Decimal must be between ${min} to ${max}.",
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={"dec"}
                    placeholder="Decimal"
                    type="number"
                    onKeyDown={(e) => e.key === "." && e.preventDefault()}
                  />
                </Form.Item> */}
                    </div>
                    <div className="weight-div">
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <div style={{ display: "flex", gap: 8 }}>
                          <label style={{ padding: "15px 0" }}>Weight:</label>
                          <Form.Item
                            name="weight_lbs"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please input weight",
                              },
                            ]}
                          >
                            <InputNumber
                              addonBefore={"lbs"}
                              placeholder="Lbs"
                              className="weight-input"
                              type="number"
                              onKeyDown={(e) => e.key === "." && e.preventDefault()}
                              style={{ width: "100%" }}
                              disabled
                            />
                          </Form.Item>
                        </div>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name="school"
                    label="School/Program"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter school/program.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please input school/program"
                      className="custom-input"
                      disabled
                    // onChange={(e)=>setUsername(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="job_title"
                    label="Job Title"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter job title.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please input job title"
                      className="custom-input"
                      disabled
                    // onChange={(e)=>setUsername(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="years_experience"
                    label="Year"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter year.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Years"
                      className="weight-input"
                      // type="number"
                      disabled
                      style={{ width: "100%", height: 48 }}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                {...formItemLayout}
                name="location"
                className="location-div"
                label="Location"
                style={{ padding: 0 }}
                rules={[
                  {
                    required: false,
                    message: "Please input your location",
                  },
                ]}
              >
                <Input
                  placeholder="Please input location"
                  className="custom-input"
                  disabled
                />
              </Form.Item>
              <Divider />


              {profileType === "player" && (
                <Form.Item
                  {...formItemLayout}
                  name="shooting_hand"
                  label="Shooting Hand"
                  className="shooting-div"
                >
                  <Radio.Group
                    onChange={(e) => setShootingHand(e.target.value)}
                    value={shootingHand}
                    disabled
                    className="custom-radio"
                  >
                    <Radio value={"right"}>Right</Radio>
                    <Radio value={"left"}>Left</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item
                {...formItemLayout}
                name="positions"
                label="Positions"
                className="sport-div position-div custom-checkbox"

              >
                <Checkbox
                  value="pg"
                  checked={positionPg}
                  onChange={(e) => setPositionPg(e.target.checked)}
                  disabled
                >
                  PG
                </Checkbox>
                <Checkbox
                  value="sg"
                  checked={positionSg}
                  onChange={(e) => setPositionSg(e.target.checked)}
                  disabled
                >
                  SG
                </Checkbox>
                <Checkbox
                  value="sf"
                  checked={positionSf}
                  onChange={(e) => setPositionSf(e.target.checked)}
                  disabled
                >
                  SF
                </Checkbox>
                <Checkbox
                  value="pf"
                  checked={positionPf}
                  onChange={(e) => setPositionPf(e.target.checked)}
                  disabled
                >
                  PF
                </Checkbox>
                <Checkbox
                  value="c"
                  checked={positionC}
                  onChange={(e) => setPositionC(e.target.checked)}
                  disabled
                >
                  C
                </Checkbox>
              </Form.Item>
              <Divider />
            </Form>
            :
            <Form
              form={form}
              name="dynamic_rule"
              className="profile-form"
              onFinish={updateProfile}
              style={{ padding: "22px 30px" }}
            >
              <Form.Item
                {...formItemLayout}
                name="avatar"
                label="Display Photo"
                className="img-div"
              >
                <div className="image-previewer">
                  {!imgSrc ? (
                    <Upload
                      className="uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}

                    >
                      Upload pic
                    </Upload>
                  ) : (
                    <>
                      <img
                        src={imgSrc}
                        alt="avatar"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        className="img"
                      />
                      <Upload
                        className="avatar-uploader"
                        showUploadList={false}
                        type="file"
                        customRequest={dummyRequest}
                        beforeUpload={handleBeforeUpload}

                      >
                        <img src={Edit} alt="edit" className="edit"></img>
                      </Upload>
                      <img
                        src={Close}
                        alt="close"
                        className="delete"
                        onClick={removeImage}
                      />
                    </>
                  )}
                </div>

                <span className="img-type" style={{marginBottom:0}}>
                  Allowed file types: PNG, JPG, JPEG.
                </span>
                <span className="img-type">Min Size:50KB Max Size:10MB</span>
                {imageUpload === true ? (
                  <span className="error-message" style={{ color: "red" }}>
                    Please upload image
                  </span>
                ) : (
                  ""
                )}
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="profile_type"
                label="Profile Type"
                className="shooting-div"
              >
                <Radio.Group
                  onChange={(e) => setProfileType(e.target.value)}
                  value={profileType}
                  // defaultValue={"player"}
                  className="custom-radio"
                >
                  <Radio value={"player"}>Player</Radio>
                  <Radio value={"coach"}>Coach</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="first_name"
                label="First Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input first name"
                  className="custom-input"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="last_name"
                label="Last Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input last name"
                  className="custom-input"
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="username"
                label="User Name"
                className="name-row"
                rules={[
                  {
                    required: true,
                    message: "Please enter username.",
                  },
                ]}
              >
                <Input
                  placeholder="Please input username"
                  className="custom-input"
                  disabled
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="dob"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please input date of birth",
                  },
                ]}
              >
                <DatePicker
                  className={"date-picker " + (selectedSport === 1 ? "orange-picker" : "blue-picker")}
                  rootClassName={selectedSport === 1 ? "orange-picker" : "blue-picker"}
                  // defaultValue={dayjs("Jan 01, 2022", dateFormat)}
                  format={dateFormat}
                  suffixIcon={<img src={Calendar} />}
                  style={{ height: "48px" }}
                />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="gender"
                label="Gender"
                className="gender-div"
                rules={[
                  {
                    required: false,
                    message: "Please input your name",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  // defaultValue={"male"}
                  // defaultChecked={"male"}

                  className="custom-radio"
                >
                  <Radio value={"male"}>Male</Radio>
                  <Radio value={"female"}>Female</Radio>
                  <Radio value={"undisclosed"}>Undisclosed</Radio>
                </Radio.Group>
              </Form.Item>
              {profileType === "player" ? (
                <>
                  <Form.Item
                    {...formItemLayout}
                    label="Measurements"
                    className="measurements-input"
                  >
                    <div style={{ display: "flex", gap: 8 }}>
                      <label style={{ padding: "15px 0" }}>Height:</label>
                      <Form.Item
                        name="height_feet"
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            max: 8,
                            pattern: new RegExp("^[0-9]*$"),
                            message: "Feet must be between ${min} to ${max}.",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={"ft"}
                          placeholder="Feet"
                          type="number"
                          value={feet}

                          onKeyDown={(e) => e.key === "." && e.preventDefault()}
                        />
                      </Form.Item>
                      <Form.Item
                        name="height_inch"
                        rules={[
                          {
                            type: "number",
                            min: 0,
                            max: 11,
                            message: "Inches must be between ${min} to ${max}.",
                          },
                        ]}
                      >
                        <InputNumber
                          addonBefore={"in"}
                          placeholder="Inch"
                          type="number"
                          value={inch}

                          onKeyDown={(e) => e.key === "." && e.preventDefault()}
                        />
                      </Form.Item>
                    </div>
                    <div className="weight-div">
                      <Form.Item
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <div style={{ display: "flex", gap: 8 }}>
                          <label style={{ padding: "15px 0" }}>Weight:</label>
                          <Form.Item
                            name="weight_lbs"
                            style={{ width: "100%" }}
                            rules={[
                              {
                                required: true,
                                message: "Please input weight",
                              },
                            ]}
                          >
                            <InputNumber
                              addonBefore={"lbs"}
                              placeholder="Lbs"
                              className="weight-input"
                              type="number"
                              onKeyDown={(e) => e.key === "." && e.preventDefault()}
                              style={{ width: "100%" }}

                            />
                          </Form.Item>
                        </div>
                      </Form.Item>
                    </div>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    {...formItemLayout}
                    name="school"
                    label="School/Program"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter school/program.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please input school/program"
                      className="custom-input"
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="job_title"
                    label="Job Title"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter job title.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please input job title"
                      className="custom-input"
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    name="years_experience"
                    label="Year"
                    className="name-row"
                    rules={[
                      {
                        required: false,
                        message: "Please enter year.",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Years"
                      className="weight-input"
                      // type="number"

                      style={{ width: "100%", height: 48 }}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                {...formItemLayout}
                name="location"
                className="location-div"
                label="Location"
                style={{ padding: 0 }}
                rules={[
                  {
                    required: false,
                    message: "Please input your location",
                  },
                ]}
              >
                <Input
                  placeholder="Please input location"
                  className="custom-input"
                />
              </Form.Item>
              <Divider />
              {profileType === "player" && (
                <Form.Item
                  {...formItemLayout}
                  name="shooting_hand"
                  label="Shooting Hand"
                  className="shooting-div"
                >
                  <Radio.Group
                    onChange={(e) => setShootingHand(e.target.value)}
                    value={shootingHand}
                    // defaultValue={"right"}

                    className="custom-radio"
                  >
                    <Radio value={"right"}>Right</Radio>
                    <Radio value={"left"}>Left</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item
                {...formItemLayout}
                name="positions"
                label="Positions"
                className="sport-div position-div custom-checkbox"

              >
                <Checkbox
                  value="pg"
                  checked={positionPg}
                  onChange={(e) => setPositionPg(e.target.checked)}
                >
                  PG
                </Checkbox>
                <Checkbox
                  value="sg"
                  checked={positionSg}
                  onChange={(e) => setPositionSg(e.target.checked)}
                >
                  SG
                </Checkbox>
                <Checkbox
                  value="sf"
                  checked={positionSf}
                  onChange={(e) => setPositionSf(e.target.checked)}
                >
                  SF
                </Checkbox>
                <Checkbox
                  value="pf"
                  checked={positionPf}
                  onChange={(e) => setPositionPf(e.target.checked)}
                >
                  PF
                </Checkbox>
                <Checkbox
                  value="c"
                  checked={positionC}
                  onChange={(e) => setPositionC(e.target.checked)}
                >
                  C
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="discard-btn"
                  onClick={() =>
                    // form.resetFields()
                    setEditForm(false)}
                >
                  Discard
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    margin: "0 8px",
                    background : darkButtonBackground
                  }}
                  className="dark-base-btn"
                  onClick={() => updateProfile()}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          }
        </div>
      )}
    </>
  );
};

const CreateProfile = () => {
  return (
    <CreateProfileContextProvider>
      <CreateProfilePage />
    </CreateProfileContextProvider>
  );
};
export default CreateProfile;
