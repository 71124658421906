import React, { useContext, useState , useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Col, Row, Select, Skeleton, Spin, Tabs } from "antd";
import "./group-detail.scss";
import User from "../../../assets/images/dual-user.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import Avatar from "react-avatar";
import EditGroupGeneralDetails from "../edit-group-general-details/edit-group-general-detail";
import OwnersMembers from "../owners-members/owners-members";
// import Workouts from "../../shared/workouts/workouts";
import Workouts from "../workouts-home/workouts-home";
import WorkoutDetails from "../workout-detail/workout-detail";
import Announcements from "../announcements/announcements";
import Posts from "../posts/posts";
import ExistingWorkout from "../existing-workout/existing-workout";
import NewWorkout from "../new-workout/new-workout";
import {
  GroupDetailContextProvider,
  GroupDetailContext,
} from "../../../coach-context/group-context/group-detail-context";
import GroupGeneralDetails from "../group-general-detail/group-general-detail";
import { UserContext } from "../../../context/user-context";
import EditWorkout from "../edit-workout/edit-workout";
import EditWorkoutAssignment from "./edit-workout-assignment/edit-workout-assignment";
import ShotChart from "../shot-chart/shot-chart";
import VisionShootingSessions from "../vision-shooting-sessions/vision-shooting-sessions";
import PrivateWorkouts from "../private-workouts"
const GroupDetailPage = () => {
  const {selectedSport} = useContext(UserContext)
  const {
    generalDetail,
    setGeneralDetail,
    loading,
    workoutDetail,
    setWorkoutDetail,
    existingWorkout,
    newWorkout,
    setNewWorkout,
    showGeneralDetails,
    setShowGeneralDetail,
    setExistingWorkout,
    imageLoaded,
    setImageLoaded,
    selectedWorkout,
    setSelectedWorkout,
    viewSubmissions, setViewSubmissions,
    getGroupDetail,
    cgList,
    groups,
    setSelectedGroup,
    params,
    selectedGroup,
    editWorkout,
    setEditWorkout,
    editAssignWorkout,
    setEditAssignWorkout
  } = useContext(GroupDetailContext);
  const [activeKey , setActiveKey] = useState("general-details")
  const onChange = (value) => {
    setActiveKey(value)
    setWorkoutDetail(false)
    setViewSubmissions(false)
    if (params.tab === "submission-detail" || params.tab === "team_shooting_sessions") {
      navigate({
        search: `?id=${generalDetail.id}&name=${generalDetail.name}`,
      });
    }
  };
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  let groupOptions = [];
  for (let i = 0; i < groups?.length; i++) {
    groupOptions.push({
      value: groups[i].id,
      label: groups[i].name,
    });
  }
  const onChangeGroup = (e , f) => {
    setGeneralDetail(generalDetail=>[])
    setSelectedGroup(f.value)
    navigate({
      pathname: `../${f.label}`,
      search: `?id=${f.value}`,
    });
  };
  useEffect(()=>{
    setActiveKey("general-details")
  },[selectedGroup])
  const items = [
    getItem(
      "General Details",
      "general-details",
      showGeneralDetails ? (
        <GroupGeneralDetails
          generalDetail={generalDetail}
          setGeneralDetail={setShowGeneralDetail}
        />
      ) : (
        <EditGroupGeneralDetails
          cgList={cgList}
          generalDetail={generalDetail}
          setGeneralDetail={setShowGeneralDetail}
        />
      )
    ),
    getItem("Owners/Members", "owners-members", <OwnersMembers generalDetail={generalDetail} getGroupDetail={getGroupDetail} />),
    getItem(
      "Workouts",
      "workouts",
      workoutDetail || params.tab === "submission-detail" ? (
        <WorkoutDetails setWorkoutDetail={setWorkoutDetail} selectedWorkout={selectedWorkout} setSelectedWorkout={setSelectedWorkout} setViewSubmissions={setViewSubmissions} viewSubmissions={viewSubmissions} generalDetail={generalDetail} setEditWorkout={setEditWorkout} editWorkout={editWorkout} setEditAssignWorkout={setEditAssignWorkout}/>
      ) : (
        <Workouts
          setWorkoutDetail={setWorkoutDetail}
          setNewWorkout={setNewWorkout}
          setExistingWorkout={setExistingWorkout}
          workoutData={generalDetail.workouts}
          setSelectedWorkout={setSelectedWorkout}
          generalDetail={generalDetail}
        />
      )
    ),
    getItem("Private Workouts", "private_Workouts", <PrivateWorkouts />),
    selectedSport === 1 &&
    getItem("Shot Chart", "shot_chart", <ShotChart />),
    selectedSport === 1 &&
    getItem("Vision Shooting Sessions", "shooting_sessions", <VisionShootingSessions />),
    getItem("Announcements", "announcements", <Announcements generalDetail={generalDetail}/>),
    // getItem("Posts & Comments", "posts", <Posts />),
  ];
  const navigate = useNavigate();
  React.useEffect(() => {
    if (params.tab === "workouts") {
      navigate({
        search: `?id=${generalDetail.id}&name=${generalDetail.name}`,
      });
    }
    else if(params.tab === "team_shooting_sessions"){

      setActiveKey("shooting_sessions")
    }
  }, [params]);
  useEffect(()=>{
    if(activeKey === "general-details" && generalDetail?.length !== 0){
      navigate({
        search: `?id=${generalDetail.id}&name=${generalDetail.name}`,
      });
    }else if(params.tab === "team_shooting_sessions"){
      navigate({
        pathname:`./`,
        search: `?id=${generalDetail.id}&name=${generalDetail.name}&session_id=${params.session_id}&tab=${params.tab}`,
      });
    }
  }, [generalDetail])
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
          size="large"
          style={{
            position: "absolute",
            left: "48%",
            top: "50%",
          }}
        />
      ) : (
        <div className="group-detail-main">
            <div style={{    position: "absolute",right: 100,top: 105}}>
              <span style={{fontSize: 16,fontWeight: 500,color: "#000"}}>Group(s):</span>
              <Select
                placeholder={generalDetail.name}
                suffixIcon={<img src={DownArrow} />}
                onChange={(e,f)=>onChangeGroup(e,f)}
                className="select-input group-list-select"
                popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
                options={groupOptions}
              />
            </div>
          {existingWorkout ? (
            <ExistingWorkout
              setWorkoutDetail={setWorkoutDetail}
              setExistingWorkout={setExistingWorkout}
              generalDetail={generalDetail}
              setSelectedWorkout={setSelectedWorkout}
            />
          ) : newWorkout ? (
            <NewWorkout
              setNewWorkout={setNewWorkout}
              setWorkoutDetail={setWorkoutDetail}
              generalDetail={generalDetail}
            />
          ) : editWorkout ? (
            <EditWorkout 
              setEditWorkout={setEditWorkout}
              selectedWorkout={selectedWorkout}
              setWorkoutDetail={setWorkoutDetail}
            />
          )
          :
          editAssignWorkout ? (
            <EditWorkoutAssignment 
            selectedWorkout={selectedWorkout} 
            setEditAssignWorkout={setEditAssignWorkout}     
            setWorkoutDetail={setWorkoutDetail} 
            />
          ) 
          :
          (
            <>
              <Row className="group-detail">
                <div
                  style={{ marginRight: 24 }}
                >
                  {generalDetail.avatar !== null && imageLoaded === "" && (
                    <Skeleton.Image
                      active={true}
                      style={{ width: "120px", height: "120px" }}
                    />
                  )}

                  {generalDetail.avatar === null ? (
                    <Avatar
                      name={generalDetail.name}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      maxInitials={2}
                      className="group-avatar"
                    />
                  ) : (
                    <img
                      src={generalDetail.avatar}
                      className="group-logo"
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    />
                  )}
                </div>
                <Col span={20} style={{ maxWidth: "85.5%" }}>
                  <div style={{ display: "flex", height: "30px" }}>
                    {" "}
                    <h2 className="group-name">{generalDetail.name}</h2>
                  </div>

                  <div style={{ display: "flex" }}>
                    <img src={User} alt="" />
                    <p className="group-handle">
                      {generalDetail.is_purchased_group === true
                        ? "Paid Public Training Group"
                        : "Team Training Group"}
                    </p>
                  </div>
                  <Row gutter={[16, 16]} className="points-row">
                    <Col span={5}>
                      <span className="points">
                        {generalDetail.members_joined}
                      </span>
                      <br />
                      <span className="category">Member(s)</span>
                    </Col>
                    <Col span={5}>
                      <span className="points">{generalDetail.workouts && generalDetail.workouts.upcoming + generalDetail.workouts.active + generalDetail.workouts.archive}</span>
                      <br />
                      <span className="category">Workout(s)</span>
                    </Col>
                    {/* <Col span={5}>
                      <span className="points">
                        {generalDetail.posts  ? generalDetail.posts.length : "N/A"}
                      </span>
                      <br />
                      <span className="category">Post(s)</span>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
              <div className="group-data">
                <Tabs
                  tabPosition="left"
                  onChange={onChange}
                  type="card"
                  className={"group-tabs " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
                  items={items}
                  destroyInactiveTabPane={true}
                  activeKey={activeKey}
                  defaultActiveKey={params.tab && params.tab}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const GroupDetail = () => {
  return (
    <GroupDetailContextProvider>
      <GroupDetailPage />
    </GroupDetailContextProvider>
  );
};
export default GroupDetail;
